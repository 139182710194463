import React from 'react'

import { useIntl } from 'react-intl'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { useAuth } from 'reactfire'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { Link, Paper } from '@mui/material'

const signInWithGoogle = async (auth: any) => {
  const provider = new GoogleAuthProvider()
  await signInWithPopup(auth, provider)
}

const LoginBox = () => {
  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.login'
  const auth = useAuth()
  return (
    <Box
      sx={{
        marginTop: 10,
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      component={Paper}
    >
      <img src="/images/logo_light_2x.png" alt="Managi" height={70} />
      <Typography component="h1" variant="h5" mt={4}>
        {i18n({ id: `${i18nNs}.title-no-auth` })}
      </Typography>
      <Typography component="h1" variant="subtitle2">
        Or{' '}
        <Link sx={{ cursor: 'pointer' }} onClick={() => signInWithGoogle(auth)}>
          create an account
        </Link>
        , it's free!
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => signInWithGoogle(auth)}
        >
          Sign In
        </Button>
      </Box>
    </Box>
  )
}

export default LoginBox
