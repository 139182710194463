import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material'
import {
  addDoc,
  collection,
  DocumentReference,
  query,
  serverTimestamp,
  where,
} from 'firebase/firestore'
import { memo, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFirestore, useFirestoreCollection } from 'reactfire'
import { Basic } from '../../layouts'
import AddIcon from '@mui/icons-material/Add'

type Props = {
  userRef: DocumentReference
}

const ProjectsList = ({ userRef }: Props) => {
  const firestore = useFirestore()
  const navigate = useNavigate()

  const projectsCollection = useMemo(() => collection(firestore, 'projects'), [firestore])
  const projectsQuery = useMemo(() => {
    return query(
      projectsCollection,
      where('softRemoved', '==', false),
      where('type', '==', 'board'),
      where('createdBy', '==', userRef),
    )
  }, [projectsCollection, userRef])
  const { status: projectsStatus, data: projectsSnaps } = useFirestoreCollection(projectsQuery)

  const sharedProjectsQuery = useMemo(() => {
    return query(
      projectsCollection,
      where('softRemoved', '==', false),
      where('authorizedUsers', 'array-contains', userRef),
    )
  }, [projectsCollection, userRef])
  const { status: sharedProjectsStatus, data: sharedProjectsSnaps } =
    useFirestoreCollection(sharedProjectsQuery)

  const handleCreateProject = useCallback(async () => {
    const newProjectRef = await addDoc(projectsCollection, {
      name: 'New project',
      softRemoved: false,
      type: 'board',
      createdAt: serverTimestamp(),
      createdBy: userRef,
    })

    navigate(`/projects/${newProjectRef?.id}`)
  }, [navigate, projectsCollection, userRef])

  return (
    <Basic key={'project-list'} userRef={userRef}>
      <Typography gutterBottom variant="h5" mb={2}>
        My Projects
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            elevation={0}
            sx={{ height: '185px', border: 'dashed' }}
            onClick={handleCreateProject}
          >
            <CardContent sx={{ textAlign: 'center', paddingTop: 5 }}>
              <Typography variant="h5" p={2}>
                Create new project
              </Typography>
              <AddIcon fontSize="large" />
            </CardContent>
          </Card>
        </Grid>
        {projectsStatus === 'loading' ? (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Skeleton variant="rounded" width="100%" height={185} />
            </Grid>
          </>
        ) : (
          projectsSnaps?.docs?.map(projectSnap => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={projectSnap.ref.path}>
              <Card
                elevation={0}
                sx={{ height: '185px' }}
                onClick={() => navigate(`/projects/${projectSnap.id}`)}
              >
                <CardContent>
                  <Typography variant="h5">{projectSnap?.data()?.name}</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">Open</Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
      <Divider sx={{ mt: 3, mb: 3 }} />
      <Typography gutterBottom variant="h5" mb={2}>
        Shared with me
      </Typography>
      <Grid container spacing={2}>
        {sharedProjectsStatus === 'loading' ? (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Skeleton variant="rounded" width="100%" height={185} />
            </Grid>
          </>
        ) : !sharedProjectsSnaps.size ? (
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              <i>No one shared projects with you yet</i>
            </Typography>
          </Grid>
        ) : (
          sharedProjectsSnaps?.docs?.map(projectSnap => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={projectSnap.ref.path}>
              <Card
                elevation={0}
                sx={{ height: '185px' }}
                onClick={() =>
                  projectSnap.id === projectSnap?.data().projectRef?.id
                    ? navigate(`/projects/${projectSnap?.data().projectRef.id}`)
                    : navigate(
                        `/projects/${projectSnap?.data().projectRef.id}/cards/${projectSnap.id}`,
                      )
                }
              >
                <CardContent>
                  <Typography variant="h5">{projectSnap?.data()?.name || 'unknown'}</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">Open shared</Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </Basic>
  )
}

export default memo(ProjectsList)
