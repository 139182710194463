import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router-dom'
import { Divider } from '@mui/material'
import { useUser } from 'reactfire'
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined'
const pages = [
  // { label: 'Dashboard', path: '/dashboard' },
  { label: 'Projects', path: '/projects' },
]

const settings = [
  { label: 'Profile', path: '/my-profile' },
  { label: 'Account', path: '/account' },
  { label: 'Logout', path: '/logout' },
]

type Props = {
  headline: string
  goBackButtonLink: string
  actions?: any
  withBackground?: boolean
  topBarComponent?: JSX.Element | JSX.Element[]
  onToggleDrawer?: () => void
}

const ResponsiveAppBar = ({ headline, topBarComponent, onToggleDrawer }: Props) => {
  const user = useUser({})
  const navigate = useNavigate()
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar position="static" color="transparent">
      <Container maxWidth={false}>
        <Toolbar disableGutters variant="dense">
          {headline && (
            <Typography
              variant="h6"
              noWrap
              sx={{
                display: 'flex',
                color: 'white',
              }}
            >
              {headline}
            </Typography>
          )}
          {topBarComponent}

          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ position: 'fixed', top: 7, left: 'calc(50% - 75px)' }}>
            <img src="/images/logo_light_small.png" alt="Managi" height={35} />
          </Box>
          <Box sx={{ display: 'flex', mr: 1 }}>
            {pages.map(page => (
              <Button
                key={page.label}
                onClick={() => navigate(page.path)}
                sx={{ color: 'white', display: 'block' }}
              >
                {page.label}
              </Button>
            ))}
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2, ml: 2, mr: 2 }} />
          <Tooltip title="Manny" arrow>
            <IconButton onClick={() => onToggleDrawer?.()}>
              <SmartToyOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2, ml: 2, mr: 3 }} />
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} size="small">
                <Avatar alt={user?.data?.displayName || '...'} src={user?.data?.photoURL || ''} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(setting => (
                <MenuItem key={setting.label} onClick={() => navigate(setting.path)}>
                  <Typography textAlign="center">{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default ResponsiveAppBar
