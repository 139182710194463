import { Form } from '@rjsf/mui'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { useCallback, useMemo } from 'react'
import _, { isUndefined as _isUndefined } from 'lodash'
import { IChangeEvent } from '@rjsf/core'
import { DocumentReference, DocumentSnapshot } from 'firebase/firestore'
import { Typography } from '@mui/material'
import { TCard } from '../../hooks/useCardTree'

function omitByRecursivelyInPlace(value: any, iteratee: any) {
  _.each(value, (v, k) => {
    if (iteratee(v, k)) {
      _.unset(value, k)
    } else if (_.isObject(v)) {
      omitByRecursivelyInPlace(v, iteratee)
    }
  })

  return value
}

type Props = {
  onSubmit: (data: Partial<TCard> & { ref: DocumentReference<TCard> }) => Promise<void>
  cardSnap?: DocumentSnapshot<TCard>
}
const AddCardForm = ({ onSubmit, cardSnap }: Props) => {
  const handleSubmit = useCallback(
    async (data: IChangeEvent<any, RJSFSchema, any>) => {
      if (!data.errors.length) {
        onSubmit(
          omitByRecursivelyInPlace(
            {
              ref: cardSnap?.ref,
              state: 'pending',
              order: 100,
              ...data.formData,
            },
            _isUndefined,
          ),
        )
      }
    },
    [cardSnap?.ref, onSubmit],
  )

  const data = useMemo(() => {
    return cardSnap?.data() || {}
  }, [cardSnap])

  return (
    <>
      <Typography variant="h5">Create a new card</Typography>
      <Form
        schema={{
          // title: 'Add a card',
          //   description: 'A simple form example.',
          type: 'object',
          required: ['title'],
          properties: {
            title: {
              type: 'string',
              title: 'Title',
            },
            description: {
              type: 'string',
              title: 'Description',
              format: 'textarea',
            },
          },
        }}
        uiSchema={{}}
        validator={validator}
        formData={data}
        onSubmit={handleSubmit}
        // widgets={{}}
        // liveValidate
      />
    </>
  )
}

export default AddCardForm
