// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAxDsKpP_z53liUuzY-mu_WJdPLfJ7EGKI",
  authDomain: "organizate-e19c9.firebaseapp.com",
  projectId: "organizate-e19c9",
  storageBucket: "organizate-e19c9.appspot.com",
  messagingSenderId: "603341788766",
  appId: "1:603341788766:web:415e45cee1b4d4e8c806cf",
  measurementId: "G-WK38SWHHJJ",
};

export const ENVIRONMENT: "staging" | "prod" | "local" = "prod";

export default firebaseConfig;
