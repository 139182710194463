import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material'
import {
  addDoc,
  collection,
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import React, { useCallback, useMemo, useState } from 'react'
import 'react-edit-text/dist/index.css'
import { useFirestoreCollection } from 'reactfire'
import LoadingCentered from '../LoadingCentered'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import DeleteIcon from '@mui/icons-material/Delete'
import { DragDropContext, Draggable, OnDragEndResponder } from 'react-beautiful-dnd'
import { StrictModeDroppable } from '../Droppable'
import { EditText, onSaveProps } from 'react-edit-text'

export type TActionableItem = {
  text: string
  solved: boolean
  softRemoved: boolean
  order: number
  createdAt: Timestamp
  createdBy: DocumentReference
  lastUpdatedAt?: Timestamp
  removedAt?: Timestamp
  removedBy?: DocumentReference
}

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? 'grey' : 'inherit',
})

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // styles we need to apply on draggable items
  ...draggableStyle,
})

type Props = {
  userRef: DocumentReference
  cardSnap: DocumentSnapshot
  showAddButton?: boolean
  showSolved?: boolean
  showDragHandler?: boolean
  showDelete?: boolean
  showEdit?: boolean
  showHelperText?: boolean
}

const ActionableItems = ({
  cardSnap,
  userRef,
  showAddButton = false,
  showSolved = false,
  showDragHandler = false,
  showDelete = false,
  showEdit = false,
  showHelperText = true,
}: Props) => {
  const [newTodoItem, setNewTodoItem] = useState('')
  const actionableItemsCollection = useMemo(
    () => collection(cardSnap.ref, 'actionableItems') as CollectionReference<TActionableItem>,
    [cardSnap],
  )
  const actionableItemsQuery = useMemo(() => {
    return query<TActionableItem>(actionableItemsCollection, where('softRemoved', '==', false))
  }, [actionableItemsCollection])
  const { status: actionableItemsStatus, data: actionableItemsSnaps } =
    useFirestoreCollection(actionableItemsQuery)

  const handleEditText = useCallback(
    async (actionableItemSnap: DocumentSnapshot<TActionableItem>, saveProps: onSaveProps) => {
      await updateDoc(actionableItemSnap.ref, {
        text: saveProps.value?.trim(),
        lastUpdatedAt: serverTimestamp(),
      })
      return
    },
    [],
  )

  const handleRemoveActionableItem = useCallback(
    async (actionableItemSnap: DocumentSnapshot<TActionableItem>) => {
      await updateDoc(actionableItemSnap.ref, {
        softRemoved: true,
        removedAt: serverTimestamp(),
        removedBy: userRef,
      })
      return
    },
    [userRef],
  )

  const handleSolve = useCallback(async (actionableItemSnap: DocumentSnapshot<TActionableItem>) => {
    await updateDoc(actionableItemSnap.ref, {
      solved: !actionableItemSnap.data()?.solved,
      lastUpdatedAt: serverTimestamp(),
    })
    return
  }, [])

  const handleNewTodoItemChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTodoItem(event.target.value)
  }, [])

  const handleAddTodoItem = useCallback(async () => {
    if (cardSnap.ref && newTodoItem) {
      const oldTodoItem = newTodoItem
      setNewTodoItem('')
      try {
        await addDoc(collection(cardSnap.ref, 'actionableItems'), {
          text: newTodoItem,
          solved: false,
          softRemoved: false,
          order: 100,
          createdBy: userRef,
          createdAt: serverTimestamp(),
        })
      } catch (e) {
        setNewTodoItem(oldTodoItem)
      }
    }
  }, [newTodoItem, cardSnap, userRef])

  const actionableItems = useMemo(() => {
    return actionableItemsSnaps?.docs
      ?.filter(actionableItemSnap => (showSolved ? true : !actionableItemSnap.data()?.solved))
      .sort((a, b) => (a.data()?.order ?? Infinity) - (b.data()?.order ?? Infinity))
  }, [actionableItemsSnaps?.docs, showSolved])

  const handleCardDragEnd = useCallback<OnDragEndResponder>(
    async result => {
      // Dropped outside the list
      if (!result.destination) {
        return
      }

      const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
      }

      const newCardsList = reorder(actionableItems, result.source.index, result.destination.index)
      const batch = writeBatch(cardSnap.ref.firestore)
      newCardsList.forEach((actionableItemSnap, index) => {
        batch.update(actionableItemSnap.ref, {
          order: index,
        })
      })

      await batch.commit()
    },
    [cardSnap.ref.firestore, actionableItems],
  )

  return (
    <Box>
      {actionableItemsStatus === 'loading' ? (
        <LoadingCentered />
      ) : (
        <List dense sx={{ pt: 0 }}>
          {!!actionableItems?.length && (
            <DragDropContext onDragEnd={handleCardDragEnd}>
              <StrictModeDroppable droppableId={`droppableActionableItemsList_${cardSnap.id}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {actionableItems.map((actionableItemSnap, index: number) => (
                      <Draggable
                        key={actionableItemSnap.ref.id}
                        draggableId={actionableItemSnap.ref.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <ListItem
                              disableGutters
                              disablePadding
                              key={index}
                              sx={{
                                transition: 'all 0.3s ease-in-out',
                              }}
                              secondaryAction={
                                showDelete && (
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    size="small"
                                    onClick={() => handleRemoveActionableItem(actionableItemSnap)}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                )
                              }
                            >
                              <ListItemIcon sx={{ minWidth: 'auto' }}>
                                {showDragHandler && (
                                  <Box {...provided.dragHandleProps} pt={'10px'} pr={1}>
                                    <DragIndicatorIcon
                                      fontSize="small"
                                      sx={{
                                        cursor: 'grab',
                                      }}
                                    />
                                  </Box>
                                )}
                                <Checkbox
                                  edge="start"
                                  checked={actionableItemSnap?.data().solved}
                                  tabIndex={-1}
                                  // disableRipple
                                  inputProps={{
                                    'aria-labelledby': actionableItemSnap?.data().text,
                                  }}
                                  onClick={() => handleSolve(actionableItemSnap)}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  showEdit ? (
                                    <EditText
                                      defaultValue={actionableItemSnap?.data().text}
                                      onSave={saveProps =>
                                        handleEditText(actionableItemSnap, saveProps)
                                      }
                                    />
                                  ) : (
                                    actionableItemSnap?.data().text
                                  )
                                }
                                sx={{
                                  ...(actionableItemSnap.data()?.solved
                                    ? { textDecoration: 'line-through' }
                                    : {}),
                                }}
                              />
                            </ListItem>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          )}
        </List>
      )}
      {showAddButton && (
        <ListItem
          disablePadding
          disableGutters
          sx={{
            transition: 'all 0.3s ease-in-out',
            p: 0,
          }}
        >
          <ListItemText
            primary={
              <TextField
                placeholder="New Actionable Item"
                value={newTodoItem}
                fullWidth
                helperText={showHelperText ? 'Press enter to save' : undefined}
                onChange={handleNewTodoItemChange}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    handleAddTodoItem()
                  }
                }}
                size="small"
                variant="outlined"
              />
            }
          />
        </ListItem>
      )}
    </Box>
  )
}

export default ActionableItems
