import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useAuth, useSigninCheck } from 'reactfire'
import { Unauthenticated as LayoutUnauthenticated } from '../layouts'
import { useNavigate } from 'react-router-dom'

const i18nNs = 'pages.logout'

const LogoutPage = () => {
  const { formatMessage: i18n } = useIntl()
  const auth = useAuth()
  const navigate = useNavigate()
  const { data: signInCheckResult } = useSigninCheck()

  useEffect(() => {
    // eslint-disable-next-line no-console
    auth.signOut().then(() => console.log('signed out'))
    navigate('/login')
  }, [auth, navigate])

  return (
    <LayoutUnauthenticated
    // sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
    >
      <Container maxWidth="xs">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h5" component="h1" align="center">
              {i18n(
                { id: `${i18nNs}.title` },
                { displayName: signInCheckResult?.user?.displayName },
              )}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </LayoutUnauthenticated>
  )
}

export default LogoutPage
