import {
  Alert,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import { DocumentReference, DocumentSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore'
import { useCallback } from 'react'
import { EditText, onSaveProps } from 'react-edit-text'
import 'react-edit-text/dist/index.css'
import EditMarkdown from '../EditMarkdown'
import DeleteIcon from '@mui/icons-material/Delete'
import ArchiveIcon from '@mui/icons-material/Archive'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import { TProject } from '../../hooks/useCardTree'

type Props = {
  userRef: DocumentReference
  projectSnap: DocumentSnapshot<TProject>
  sx?: SxProps<Theme>
  compact: boolean
}

const ProjectDetails = ({ sx, projectSnap, userRef, compact = false }: Props) => {
  const handleEditTitle = useCallback(
    async (saveProps: onSaveProps) => {
      projectSnap.ref &&
        saveProps.value?.trim() &&
        (await updateDoc(projectSnap.ref, {
          name: saveProps.value?.trim(),
          lastUpdatedAt: serverTimestamp(),
          lastUpdatedBy: userRef,
        }))
      return
    },
    [projectSnap.ref, userRef],
  )

  const handleEditDescription = useCallback(
    async (value: string) => {
      projectSnap.ref &&
        (await updateDoc(projectSnap.ref, {
          description: value,
          lastUpdatedAt: serverTimestamp(),
          lastUpdatedBy: userRef,
        }))
      return
    },
    [projectSnap.ref, userRef],
  )

  const handleDelete = useCallback(async () => {
    if (
      projectSnap?.data()?.softRemoved ||
      window.confirm('Are you sure you want to remove this project?')
    ) {
      projectSnap.ref &&
        (await updateDoc(projectSnap.ref, {
          softRemoved: !projectSnap?.data()?.softRemoved,
          removedAt: serverTimestamp(),
          removedBy: userRef,
        }))
    }
  }, [projectSnap, userRef])

  const handleArchive = useCallback(async () => {
    if (projectSnap.ref) {
      const newArchived = !projectSnap.data()?.archived

      await updateDoc(projectSnap.ref, {
        archived: newArchived,
        ...(projectSnap.data()?.archived
          ? {
              unarchivedAt: serverTimestamp(),
              unarchivedBy: userRef,
            }
          : {
              archivedAt: serverTimestamp(),
              archivedBy: userRef,
            }),
      })
    }
    return
  }, [projectSnap, userRef])

  return (
    <Paper
      sx={{
        ...sx,
        height: '100%',
      }}
      elevation={4}
    >
      <Box
        sx={{
          padding: '10px',
          maxHeight: '87vh',
          height: '87vh',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
        key={projectSnap.ref?.path}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Typography variant="h6" component="div">
            <EditText
              defaultValue={projectSnap?.data()?.name}
              onSave={handleEditTitle}
              style={{
                marginLeft: 2,
                marginTop: 1,
                overflow: 'clip',
              }}
            />
          </Typography>
          <Stack direction="row" spacing={1}>
            <Tooltip arrow title={projectSnap.data()?.archived ? 'Unarchive' : 'Archive'}>
              <IconButton onClick={handleArchive} size="small">
                {projectSnap.data()?.archived ? (
                  <UnarchiveIcon fontSize="small" />
                ) : (
                  <ArchiveIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip arrow title={projectSnap.data()?.softRemoved ? 'Recover' : 'Delete'}>
              <IconButton onClick={handleDelete} size="small">
                {projectSnap.data()?.softRemoved ? (
                  <RestoreFromTrashIcon fontSize="small" />
                ) : (
                  <DeleteIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        {/* <Typography color="text.primary" variant="h6" noWrap></Typography> */}
        <Divider />
        {projectSnap.data()?.softRemoved && (
          <Alert sx={{ marginTop: 1 }} severity="error">
            This project is deleted
          </Alert>
        )}
        {projectSnap.data()?.archived && (
          <Alert sx={{ marginTop: 1 }} severity="warning">
            This project is archived
          </Alert>
        )}

        <Grid container>
          <Grid item xs={compact ? 12 : 9}>
            <EditMarkdown
              value={projectSnap?.data()?.description || ''}
              onEdit={handleEditDescription}
            />
          </Grid>

          <Grid item xs={compact ? 12 : 3}>
            {/* <Metadata projectSnap={projectSnap} /> */}
            <Divider />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default ProjectDetails
