import { IconButton } from '@mui/material'
import { DocumentReference } from 'firebase/firestore'
import ForumIcon from '@mui/icons-material/Forum'
import { useCallback, useState } from 'react'
import CommentsBox from './CommentsBox'
import HtmlTooltip from '../HtmlTooltip'

type Props = {
  cardRef: DocumentReference
  userRef: DocumentReference
}

const CommentsIcon = ({ cardRef, userRef }: Props) => {
  const [openComments, setOpenComments] = useState(false)

  const handleOpenComments = useCallback(() => {
    setOpenComments(!openComments)
  }, [openComments])

  return (
    <HtmlTooltip
      open={openComments}
      onClose={() => setOpenComments(false)}
      onOpen={() => setOpenComments(true)}
      title={<CommentsBox userRef={userRef} cardRef={cardRef} />}
      arrow
      placement="right"
      disableFocusListener
      disableHoverListener
      disableTouchListener
      // PopperProps={{
      //     disablePortal: true,
      // }}
    >
      <IconButton size="small" onClick={handleOpenComments}>
        <ForumIcon fontSize="small" />
      </IconButton>
    </HtmlTooltip>
  )
}

export default CommentsIcon
