import themeCommon from './themeCommon'
import colors from './colors'

const themeLight = {
  ...themeCommon,
  custom: {
    errorPastel: {
      light: '#FFCDD2',
      main: '#EF9A9A',
      dark: '#EF5350',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    warningPastel: {
      light: '#FFF59D',
      main: '#FFE0B2',
      dark: '#FFCC80',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    successPastel: {
      light: '#C8E6C9',
      main: '#A5D6A7',
      dark: '#81C784',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    columns: {
      default: '#E5E7E9',
      main: '#FFF59D',
    },
    cards: {
      default: '#ffe4e1',
    },
    background: {
      default: '#E5E7E9',
      darkBox: '#F0F0F0',
      lightBox: 'FFFFFF',
    },
  },
  palette: {
    type: 'light',
    common: {
      black: 'rgba(0, 0, 0, 0.87)',
      white: '#FFFFFF',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    primary: {
      light: 'rgba(154, 209, 212, 1)',
      main: '#673AB7',
      dark: 'rgba(38, 107, 149, 1)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(255, 221, 75, 1)',
      main: 'rgba(255, 171, 0, 1)',
      dark: 'rgba(238, 150, 2, 1)',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    // text: {
    //   primary: 'rgba(0, 0, 0, 0.87)',
    //   secondary: 'rgba(0, 0, 0, 0.54)',
    //   disabled: 'rgba(0, 0, 0, 0.38)',
    //   hint: 'rgba(0, 0, 0, 0.38)',
    // },
  },
  components: {
    // Style sheet name ⚛️
    MuiIconButton: {
      // Name of the rule
      root: {
        // Some CSS
        color: '#90A4AE',
        padding: 8,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#FFF',
          border: 'none',
          boxShadow: 'rgb(0 0 0 / 16%) 0px 12px 60px -12px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          border: 'none',
          boxShadow: 'rgb(0 0 0 / 16%) 0px 12px 60px -12px',
        },
        paper: {
          border: 'none',
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        containedSecondary: {
          backgroundImage: `linear-gradient(-45deg,${colors.main.carolinaBlue},${colors.main.paleRobinEggBlue} 99.9%)`,
          boxShadow: `${colors.main.paleRobinEggBlue} 0px 4px 12px -4px`,
          '&:hover': {
            boxShadow: `${colors.main.paleRobinEggBlue} 0px 4px 18px -4px`,
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          backgroundImage: `linear-gradient(-45deg,${colors.main.carolinaBlue},${colors.main.paleRobinEggBlue} 99.9%)`,
          boxShadow: `${colors.main.paleRobinEggBlue} 0px 4px 12px -4px`,
          '&:hover': {
            boxShadow: `${colors.main.paleRobinEggBlue} 0px 4px 18px -4px`,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        main: {
          marginTop: 4,
          backgroundColor: '#FFF',
          borderRadius: '10px',
        },
        root: {
          border: 'none',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '& .MuiCard-root': {
            boxShadow: 'rgb(0 0 0 / 16%) 0px 4px 8px -4px',
            '&:hover': {
              cursor: 'pointer',
              boxShadow: 'rgb(0 0 0 / 48%) 0px 4px 8px -4px',
            },
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: '#FFF',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          background: '#FFF',
        },
      },
    },
  },
}

export default themeLight
