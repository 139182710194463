import { useMemo } from 'react'
import { useFirestore } from 'reactfire'
import { collection, CollectionReference } from 'firebase/firestore'

export default function useFirestoreCollectionRef<T>(path: string, ...pathSegments: string[]) {
  const firestore = useFirestore()
  const collectionRef = useMemo(
    () => collection(firestore, path, ...pathSegments) as CollectionReference<T>,
    // We ignore pathSegments because it is always a new reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firestore, path],
  )
  return collectionRef
}
