import React from 'react'

const LoadingAnimation: React.FC = () => {
  return (
    <>
      <div className="col-3 center">
        <div className="stage">
          <div className="dot-flashing"></div>
        </div>
      </div>
      <style>{`
        .center {
          align-items: center;
          display: flex;
          padding-left: 12px;
        }
        .stage {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .dot-flashing {
          position: relative;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #ffc21f;
          color: #ffc21f;
          animation: dot-flashing 1s infinite linear alternate;
          animation-delay: 0.5s;
        }
        .dot-flashing::before,
        .dot-flashing::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0;
        }
        .dot-flashing::before {
          left: -15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #ffc21f;
          color: #ffc21f;
          animation: dot-flashing 1s infinite alternate;
          animation-delay: 0s;
        }
        .dot-flashing::after {
          left: 15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #ffc21f;
          color: #ffc21f;
          animation: dot-flashing 1s infinite alternate;
          animation-delay: 1s;
        }

        @keyframes dot-flashing {
          0% {
            background-color: #ffc21f;
          }
          50%,
          100% {
            background-color: #ffc21f99;
          }
        }
      `}</style>
    </>
  )
}
// color 1 #ffc21f99
// color 2 #ffc21f;

export default LoadingAnimation
