import { Box } from '@mui/material'

function Header({ children }: { children: React.ReactNode | React.ReactNode[] }) {
  return (
    <Box
      sx={{
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        color: 'primary.contrastText',
        // backgroundColor: 'primary.main',
      }}
    >
      {children}
    </Box>
  )
}

export default Header
