import { Box } from '@mui/material'

function MessageIconContainer({ children }: { children: React.ReactNode | React.ReactNode[] }) {
  return (
    <Box
      sx={{ padding: 2, paddingRight: 0, height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Box sx={{ height: 24, display: 'flex', alignItems: 'center' }}>{children}</Box>
    </Box>
  )
}

export default MessageIconContainer
