import { Paper, Typography } from '@mui/material'
import { DocumentSnapshot } from 'firebase/firestore'
import { TCard } from '../../../hooks/useCardTree'
import { Stack } from '@mui/system'
import useUsers from '../../../hooks/useUsers'

type Props = {
  cardSnap: DocumentSnapshot<TCard>
}
const Metadata = ({ cardSnap }: Props) => {
  const { getUserById } = useUsers()
  return (
    <Paper sx={{ width: '100%', p: 1, pl: 2, pr: 2, background: 'transparent' }} elevation={0}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
          Created by{' '}
          {cardSnap.data()?.createdBy
            ? //   @ts-expect-error
              getUserById(cardSnap.data()!.createdBy?.id)?.data?.()?.displayName ||
              cardSnap.data()?.createdBy!.id
            : '...'}
        </Typography>
      </Stack>
    </Paper>
  )
}

export default Metadata
