import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Box, Button, Stack, TextareaAutosize } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

type Props = {
  value: string
  onEdit: (value: string) => Promise<void>
}

const EditMarkdown = ({ value, onEdit }: Props) => {
  const [editMode, setEditMode] = useState(false)

  const [text, setText] = useState(value)

  useEffect(() => {
    setText(value)
  }, [value])

  const handleToggleEditMode = useCallback(() => {
    !editMode && setEditMode(!editMode)
  }, [editMode])

  const handleChangeText = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value)
  }, [])

  const cancelEdit = useCallback(() => {
    setText(value)
    setEditMode(false)
  }, [value])

  const saveEdit = useCallback(() => {
    onEdit(text || '').then(() => {
      setEditMode(false)
    })
  }, [onEdit, text])

  return (
    <Box
      sx={{
        minHeight: '175px',
        marginTop: 1,
        // p: 0.5,
        marginBottom: 1,
        boxShadow: 'none',
        transition: 'box-shadow 0.3s',
        ...(editMode
          ? {}
          : {
              '&:hover': {
                // backgroundColor: '#F6F6F6',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
              },
            }),
      }}
      onClick={handleToggleEditMode}
    >
      {editMode ? (
        <TextareaAutosize
          aria-label="markdown textarea"
          placeholder="Description..."
          style={{ width: '100%', backgroundColor: 'grey' }}
          minRows={10}
          maxRows={15}
          value={text}
          onChange={handleChangeText}
          onBlur={saveEdit}
        />
      ) : (
        <ReactMarkdown children={text || '_Description..._'} remarkPlugins={[remarkGfm]} />
      )}
      {editMode && (
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <Button size="small" variant="text" onClick={cancelEdit}>
            Cancel
          </Button>
          {/* <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            color="primary"
            size="small"
            onClick={saveEdit}
          >
            Save
          </LoadingButton> */}
        </Stack>
      )}
    </Box>
  )
}

export default EditMarkdown
