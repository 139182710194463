import React, { useCallback, useState } from 'react'
import {
  Box,
  InputAdornment,
  Input,
  IconButton,
  FormControl,
  Paper,
  CircularProgress,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'

type Props = {
  isLoading?: boolean
  onSubmit: (message: string) => Promise<void>
}
const InputBox = ({ isLoading = false, onSubmit }: Props) => {
  const [message, setMessage] = useState('')

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
  }, [])

  const handleSubmit = useCallback(async () => {
    if (message.trim()) {
      await onSubmit(message.trim())
      setMessage('')
    }
  }, [message, onSubmit])

  const handleKeyDown = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        handleSubmit()
      }
    },
    [handleSubmit],
  )

  return (
    <Box p={2} pb={0.5} sx={{ marginTop: 'auto' }}>
      <FormControl
        variant="filled"
        fullWidth
        size="small"
        component={Paper}
        sx={{ padding: 1, color: 'primary.contrastText', backgroundColor: 'primary.main' }}
        onSubmit={handleSubmit}
      >
        <Input
          autoFocus
          type="text"
          value={message}
          multiline
          disabled={isLoading}
          sx={{ color: 'primary.contrastText' }}
          endAdornment={
            <InputAdornment position="end" sx={{ padding: 2 }}>
              <IconButton
                disabled={isLoading}
                color="secondary"
                onClick={message.trim().length ? handleSubmit : undefined}
                size="small"
              >
                {isLoading ? (
                  <CircularProgress size="20px" color="secondary" />
                ) : (
                  <SendIcon fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          }
          onChange={handleChange}
          onKeyDown={message.trim().length ? handleKeyDown : undefined}
        />
      </FormControl>
    </Box>
  )
}

export default InputBox
