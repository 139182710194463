import { useFirestoreCollection } from 'reactfire'
import {
  collection,
  CollectionReference,
  DocumentReference,
  query,
  Timestamp,
  where,
} from 'firebase/firestore'
import { useMemo } from 'react'

export type TProject = {
  name: string
  description?: string
  softRemoved: boolean
  archived: boolean
  createdBy?: DocumentReference
  createdAt: Timestamp
  lastUpdatedBy?: DocumentReference
  lastUpdatedAt?: Timestamp
  removedBy?: DocumentReference
  removedAt?: Timestamp
  unarchivedBy?: DocumentReference
  unarchivedAt?: Timestamp
  archivedBy?: DocumentReference
  archivedAt?: Timestamp
}

export type TCard = {
  title: string
  description: string
  order: number
  priority?: number
  state: 'pending' | 'completed' | 'warning' | 'blocked' | 'inProgress'
  archived: boolean
  softRemoved: boolean
  parentCardRef: DocumentReference<TCard> | null
  dates?: {
    start?: Timestamp | null
    end?: Timestamp | null
  }
  createdBy?: DocumentReference
  createdAt: Timestamp
  lastUpdatedBy?: DocumentReference
  lastUpdatedAt?: Timestamp
  removedBy?: DocumentReference
  removedAt?: Timestamp
  unarchivedBy?: DocumentReference
  unarchivedAt?: Timestamp
  archivedBy?: DocumentReference
  archivedAt?: Timestamp
}
export interface ICardTree extends TCard {
  ref: DocumentReference<TCard>
  children: ICardTree[]
}

export default function useCardTree(
  projectRef: DocumentReference<TProject>,
  cardRef?: DocumentReference<TCard>,
) {
  const cardsQuery = query(
    collection(projectRef, 'cards') as CollectionReference<TCard>,
    where('softRemoved', '==', false),
  )
  const { status: cardsStatus, data: cardsQuerySnap } = useFirestoreCollection(cardsQuery)

  const cardsTree = useMemo(() => {
    if (cardsStatus === 'loading') {
      return []
    }
    const cardsSnaps = cardsQuerySnap?.docs || []
    const cardMap: Record<string, ICardTree> = {}

    // Create a map of cards using their ids as keys
    for (const cardSnap of cardsSnaps) {
      const treeNode: ICardTree = { ...cardSnap.data(), ref: cardSnap.ref, children: [] }
      cardMap[cardSnap.id] = treeNode
    }

    const cardTree: ICardTree[] = []

    // Build the card tree by assigning children to their respective parent nodes
    for (const cardSnap of cardsSnaps) {
      const treeNode = cardMap[cardSnap.id]
      if (cardSnap.data()?.parentCardRef) {
        const parentTreeNode = cardMap[cardSnap.data()?.parentCardRef!.id]
        parentTreeNode?.children.push(treeNode)
      } else {
        cardTree.push(treeNode)
      }
    }

    return cardTree
  }, [cardsQuerySnap?.docs, cardsStatus])
  return { status: cardsStatus, data: cardsTree }
}
