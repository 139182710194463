import React, { memo, useCallback, useMemo } from 'react'
import Link, { LinkProps } from '@mui/material/Link'
import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { useFirestoreCollection, useFirestoreDoc } from 'reactfire'
import { Box, Button, CircularProgress, Modal, Paper } from '@mui/material'
import BoardColumn, { WIDTH_COLUMN } from './BoardColumn'
import BoardBreadcrumbs from './BoardBreadcrumbs'
import AddCardForm from './AddCardForm'
import { useAsync } from 'react-use'

import { DragDropContext, Draggable, OnDragEndResponder } from 'react-beautiful-dnd'
import { StrictModeDroppable } from '../Droppable'
import CardDetails from './CardDetails'
import { Basic } from '../../layouts'
import EditIcon from '@mui/icons-material/Edit'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'
import { EditText, onSaveProps } from 'react-edit-text'
import { TCard, TProject } from '../../hooks/useCardTree'
import ProjectDetails from './ProjectDetails'

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />
}

type Props = {
  parentCardRef: TCard['parentCardRef']
  projectSnap: DocumentSnapshot<TProject>
  userRef: DocumentReference
}

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // change background colour if dragging
  // background: isDragging ? 'lightgreen' : 'inherit',
  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? 'grey' : 'inherit',
  display: 'flex',
  margin: 0,
  // overflow: 'auto',
})

const Board = ({ projectSnap, parentCardRef, userRef }: Props) => {
  const [searchParams] = useSearchParams()

  const [openAddCardModal, setOpenAddCardModal] = React.useState(false)
  const [showArchived, setShowArchived] = React.useState(false)
  const [collapseCards, setCollapseCards] = React.useState(false)

  const [openCardDetailsModal, setOpenCardDetailsModal] = React.useState<DocumentSnapshot<TCard>>()
  const [parentCardRefs, setParentCardRefs] = React.useState<(DocumentReference<TCard> | null)[]>(
    [],
  )

  const { status: parentCardStatus, data: parentCardSnap } = useFirestoreDoc<TCard>(
    (parentCardRef || doc(projectSnap.ref, 'cards/dummy')) as DocumentReference<TCard>,
  )

  const handleToggleAddCardModal = useCallback(
    (open: boolean, parentCards?: TCard['parentCardRef'][]) => {
      setOpenAddCardModal(open)
      setParentCardRefs(!open ? [] : parentCards || [])
    },
    [],
  )

  const handleToggleCardDetailsModal = useCallback((cardSnap?: DocumentSnapshot<TCard>) => {
    setOpenCardDetailsModal(cardSnap)
  }, [])

  const cardsCollection = useMemo(() => {
    return collection(projectSnap.ref, 'cards') as CollectionReference<TCard>
  }, [projectSnap.ref])

  const cardsQuery = useMemo(() => {
    return query(
      cardsCollection,
      where('softRemoved', '==', false),
      where('parentCardRef', '==', parentCardRef || null),
      orderBy('order'),
    )
  }, [cardsCollection, parentCardRef])

  const { status: cardsStatus, data: cardsQuerySnap } = useFirestoreCollection(cardsQuery)

  const cards = useMemo(() => {
    return cardsStatus === 'success'
      ? cardsQuerySnap.docs
          .filter(cardSnap => {
            const priorities = searchParams.getAll('priority')
            if (priorities?.length) {
              if (
                ((typeof cardSnap.data()?.priority !== 'number' ||
                  cardSnap.data().priority! >= 6) &&
                  priorities.includes('NP')) ||
                (typeof cardSnap.data()?.priority === 'number' &&
                  priorities.map(Number).includes(cardSnap.data().priority!))
              ) {
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          })
          .filter(cardSnap => {
            const state = searchParams.getAll('state')
            if (state?.length) {
              if (state.includes(cardSnap.data().state)) {
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          })
      : []
  }, [cardsQuerySnap?.docs, cardsStatus, searchParams])

  // const handleEditName = useCallback(
  //   async (saveProps: onSaveProps) => {
  //     saveProps.value?.trim() &&
  //       (await updateDoc(projectSnap.ref, {
  //         name: saveProps.value?.trim(),
  //         lastUpdatedAt: serverTimestamp(),
  //       }))
  //     return
  //   },
  //   [projectSnap.ref],
  // )

  const handleSubmitCard = useCallback(
    async ({ ref, ...data }: Partial<TCard> & { ref: DocumentReference<TCard> }) => {
      handleToggleAddCardModal(false)
      for (const parentCardRef of parentCardRefs) {
        if (ref) {
          await updateDoc(ref, {
            parentCardRef: parentCardRef,
            lastUpdatedBy: userRef,
            lastUpdatedAt: serverTimestamp(),
            ...data,
          })
        } else {
          await addDoc(cardsCollection, {
            title: '',
            state: 'pending',
            archived: false,
            description: '',
            softRemoved: false,
            parentCardRef: parentCardRef,
            createdAt: serverTimestamp(),
            createdBy: userRef,
            order: cardsQuerySnap?.size + 1 || 1000,
            ...data,
          })
        }
      }
    },
    [cardsCollection, cardsQuerySnap?.size, handleToggleAddCardModal, parentCardRefs, userRef],
  )

  const parentCardsSnaps = useAsync(async () => {
    let latestParentCardRef = parentCardRef
    const breadcrumbs = []
    while (!!latestParentCardRef) {
      const parentCardSnap = await getDoc(latestParentCardRef)
      latestParentCardRef = parentCardSnap.data()!.parentCardRef
      breadcrumbs.push(parentCardSnap)
    }
    return breadcrumbs.reverse()
  }, [parentCardRef?.path])

  const handleColumnDragEnd = useCallback<OnDragEndResponder>(
    async dragResult => {
      const { destination, draggableId } = dragResult
      // Dropped outside the list
      if (!destination) {
        return
      }

      const reorder = (
        list: DocumentSnapshot<TCard>[],
        endIndex: number,
        newItem: DocumentSnapshot<TCard>,
      ) => {
        const result = Array.from(list)

        if (result.findIndex(c => c.id === newItem.id) >= 0) {
          result.splice(
            result.findIndex(c => c.id === newItem.id),
            1,
          )
        }
        result.splice(endIndex, 0, newItem)
        return result
      }

      const draggedCardRef = doc(cardsCollection, draggableId)
      const draggedCardSnap = await getDoc(draggedCardRef)
      const newParentCardRef =
        destination?.droppableId === 'null' ? null : doc(cardsCollection, destination?.droppableId)

      // Avoid putting itself as parent
      if (newParentCardRef?.id === draggedCardRef.id) {
        return
      }

      const batch = writeBatch(projectSnap.ref.firestore)

      const newParentCards = await getDocs(
        query(
          cardsCollection,
          where('softRemoved', '==', false),
          where('parentCardRef', '==', newParentCardRef),
          orderBy('order'),
        ),
      )

      const newCardsList = reorder(newParentCards?.docs, destination.index, draggedCardSnap)

      newCardsList.forEach((cardSnap, index) => {
        batch.update(cardSnap.ref, {
          parentCardRef: newParentCardRef,
          order: index,
        })
      })

      await batch.commit()
    },
    [cardsCollection, projectSnap.ref.firestore],
  )

  return (
    <Basic
      userRef={userRef}
      key={parentCardRef?.path || projectSnap?.ref.path}
      pt={0}
      // topBarComponent={
      //   <LinkRouter underline="hover" color="white" to={`/projects/${projectSnap.id}`} variant="h6">
      //     <EditText
      //       defaultValue={projectSnap.data()?.name || 'Root'}
      //       showEditButton
      //       style={{
      //         background: 'none',
      //         cursor: 'pointer',
      //       }}
      //       editButtonProps={{
      //         style: {
      //           backgroundColor: 'transparent',
      //           cursor: 'pointer',
      //           color: 'white',
      //         },
      //       }}
      //       editButtonContent={<EditIcon fontSize="small" />}
      //       onSave={handleEditName}
      //     />
      //   </LinkRouter>
      // }
    >
      <BoardBreadcrumbs
        projectSnap={projectSnap}
        cardsSnaps={parentCardsSnaps.loading ? undefined : parentCardsSnaps.value || []}
        userRef={userRef}
        showArchived={showArchived}
        collapseCards={collapseCards}
        onToggleShowArchived={() => setShowArchived(!showArchived)}
        onToggleCollapseCards={() => setCollapseCards(!collapseCards)}
      />
      {/* MODAL WITH DETAILS */}
      <Modal
        open={!!openCardDetailsModal}
        onClose={() => handleToggleCardDetailsModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          {openCardDetailsModal && (
            <CardDetails userRef={userRef} cardSnap={openCardDetailsModal} compact={false} />
          )}
        </Box>
      </Modal>

      {/* MODAL TO ADD CARDS */}
      <Modal
        open={openAddCardModal}
        onClose={() => handleToggleAddCardModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            border: 0,
            p: 2,
          }}
          component={Paper}
        >
          <AddCardForm onSubmit={handleSubmitCard} />
        </Box>
      </Modal>

      {/* BOARD COLUMNS */}
      <Box
        sx={{
          maxHeight: 'calc(100vh - 110px)',
          height: 'calc(100vh - 110px)',
        }}
      >
        {cardsStatus === 'loading' ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div
            style={{
              maxWidth: '100vw',
              height: '100%',
              overflowY: 'hidden',
              overflowX: 'auto',
              display: 'flex',
              justifyContent: 'flex-start',
              margin: 'auto',
              minHeight: 'calc(100vh - 144px)',
              alignItems: 'flex-start',
            }}
          >
            {parentCardStatus === 'loading' ? (
              <CircularProgress />
            ) : parentCardRef ? (
              <CardDetails
                compact={true}
                sx={{
                  minWidth: !!cards?.length ? '350px' : 'calc(100vw - 305px)',
                  maxWidth: !!cards?.length ? '350px' : 'calc(100vw - 305px)',
                }}
                userRef={userRef}
                cardSnap={parentCardSnap}
              />
            ) : (
              <ProjectDetails
                compact={true}
                sx={{
                  minWidth: !!cards?.length ? '350px' : 'calc(100vw - 305px)',
                  maxWidth: !!cards?.length ? '350px' : 'calc(100vw - 305px)',
                }}
                userRef={userRef}
                projectSnap={projectSnap}
              />
            )}
            <DragDropContext onDragEnd={handleColumnDragEnd}>
              <StrictModeDroppable droppableId={parentCardRef?.id || 'null'} direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {cards.map((cardSnap, index) => {
                      return (
                        <Draggable key={cardSnap.id} draggableId={cardSnap.id} index={index}>
                          {(provided, snapshot) => (
                            <StrictModeDroppable droppableId={cardSnap.id}>
                              {(colDDProvider, colDDSnapshot) => (
                                <Box
                                  sx={{
                                    display:
                                      showArchived || !cardSnap?.data()?.archived
                                        ? 'inherit'
                                        : 'none',
                                    m: 0,
                                    p: 0,
                                  }}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}
                                >
                                  <BoardColumn
                                    key={cardSnap.id}
                                    userRef={userRef}
                                    cardSnap={cardSnap}
                                    colDDProvider={colDDProvider}
                                    colDDSnapshot={colDDSnapshot}
                                    onToggleAddCardModal={handleToggleAddCardModal}
                                    onToggleCardDetailsModal={handleToggleCardDetailsModal}
                                    handlerProps={provided.dragHandleProps}
                                    showArchived={showArchived}
                                    collapseCards={collapseCards}
                                  />
                                </Box>
                              )}
                            </StrictModeDroppable>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </DragDropContext>

            <Box
              component={Paper}
              sx={{
                // background: '#FAFAFA',
                border: 'dashed 1px #ccc',
                borderRadius: 3,
                margin: '0 10px',
              }}
              onClick={() => handleToggleAddCardModal(true, [parentCardRef])}
            >
              <div
                style={{
                  padding: '10px',
                  minHeight: '100%',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  width: `${WIDTH_COLUMN - 20}px`,
                }}
              >
                <Button fullWidth sx={{ textAlign: 'center' }}>
                  Add column
                </Button>
              </div>
            </Box>
          </div>
        )}
      </Box>
    </Basic>
  )
}

export default memo(Board)
