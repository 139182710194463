import React, { useCallback, useState } from 'react'
import Popover from '@mui/material/Popover'
import { Box, IconButton, List, ListItem, ListItemButton, ListItemIcon } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

type Props = {
  keyName: string
  Icon: JSX.Element
  options: {
    value: string
    Icon: JSX.Element
  }[]
}

export default function IconMenu({ keyName, Icon, options }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const handleItemClick = useCallback(
    (item: string) => {
      const existentParams = Array.from(new Set(searchParams.keys()))

      if (searchParams.getAll(keyName).some(selectedItem => selectedItem === item)) {
        setSearchParams(searchParams => ({
          ...Object.fromEntries(existentParams.map(p => [p, searchParams.getAll(p)])),
          [keyName]: searchParams.getAll(keyName).filter(selectedItem => selectedItem !== item),
        }))
      } else {
        setSearchParams(searchParams => ({
          ...Object.fromEntries(existentParams.map(p => [p, searchParams.getAll(p)])),
          [keyName]: [...searchParams.getAll(keyName), item],
        }))
      }
    },
    [keyName, searchParams, setSearchParams],
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        size="small"
        color={!!searchParams.getAll(keyName).length ? 'secondary' : 'inherit'}
      >
        {Icon}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ bgcolor: 'background.paper' }}>
          <nav aria-label={keyName}>
            <List disablePadding>
              {options.map(o => (
                <ListItem disablePadding disableGutters onClick={() => handleItemClick(o.value)}>
                  <ListItemButton selected={searchParams.getAll(keyName).includes(o.value)}>
                    <ListItemIcon sx={{ minWidth: 0 }}>{o.Icon}</ListItemIcon>
                    {/* <ListItemText primary="Inbox" /> */}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </nav>
        </Box>
      </Popover>
    </Box>
  )
}
