import { useMemo } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import 'firebase/firestore'
import { getFirestore } from 'firebase/firestore'
import { AuthProvider, FirestoreProvider, useFirebaseApp } from 'reactfire'
import getTheme from './styles/getTheme'
// Import auth directly because most components need it
// Other Firebase libraries can be lazy-loaded as-needed
import { getAuth } from 'firebase/auth'
import Router from './pages/Router'
import DarkModeProvider from './providers/DarkModeProvider'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import IntlProvider from './intl/IntlProvider'

const darkModeConfig = {
  useLocalStorage: true,
}

function App() {
  const firebaseApp = useFirebaseApp()
  const firestoreInstance = getFirestore(firebaseApp)
  const auth = useMemo(() => getAuth(firebaseApp), [firebaseApp])

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <CssBaseline />
        <DarkModeProvider config={darkModeConfig}>
          {([colorScheme]) => (
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider
                //key={colorScheme}
                theme={getTheme(colorScheme)}
              >
                <IntlProvider>
                  <Router />
                </IntlProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          )}
        </DarkModeProvider>
      </FirestoreProvider>
    </AuthProvider>
  )
}

export default App
