import { ReactElement, useCallback, useState } from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import { motion } from 'framer-motion'
import MainWrapper from './MainWrapper'
import { Box, Drawer, Paper } from '@mui/material'
import TopBar from './components/TopBar'
import { useTheme } from '@mui/material'
import Conversation from '../components/Chat/Conversation'
import { DocumentReference } from 'firebase/firestore'

const useStyles = makeStyles(theme => ({
  mainColumn: {
    position: 'relative',
    width: '100%',
    // color: theme.palette.text.primary,
    // backgroundColor: theme.palette.background.paper,
  },
  content: {
    height: '100vh',
    overflow: 'auto',
  },
}))
type TReactElement = ReactElement | ReactElement[] | null
type TBasicProps = {
  children: TReactElement
  userRef: DocumentReference
  sectionTitle?: string
  subDrawer?: TReactElement
  topBarActions?: TReactElement
  goBackButtonLink?: string
  topBarWithBackground?: boolean
  topBarComponent?: JSX.Element | JSX.Element[]
  className?: string
  p?: number
  pt?: number
}

export const Basic = ({
  children,
  userRef,
  sectionTitle = '',
  topBarActions,
  goBackButtonLink = '',
  topBarWithBackground,
  topBarComponent,
  className = '',
  p = 2,
  pt = 2,
}: TBasicProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const drawerWidth = 340

  const handleToggleDrawer = useCallback(() => {
    setDrawerOpen(open => !open)
  }, [])
  return (
    <MainWrapper>
      <Box className={`${classes.mainColumn} ${className}`}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className={clsx({ [classes.content]: true })}
        >
          <TopBar
            headline={sectionTitle}
            actions={topBarActions}
            goBackButtonLink={goBackButtonLink}
            withBackground={topBarWithBackground}
            topBarComponent={topBarComponent}
            onToggleDrawer={handleToggleDrawer}
          />
          <Drawer
            variant="persistent"
            anchor="right"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
              },
            }}
            open={drawerOpen}
          >
            <Box>
              <Conversation onClose={() => handleToggleDrawer()} userRef={userRef} />
            </Box>
          </Drawer>

          <Paper
            sx={{
              height: `calc(100vh - 68.5px - 2 + ${pt})`,
              p: p,
              pt: pt,
              backgroundColor: 'transparent',
            }}
          >
            {children}
          </Paper>
        </motion.div>
      </Box>
    </MainWrapper>
  )
}
