import { doc } from 'firebase/firestore'
import { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useFirestore, useFirestoreDoc } from 'reactfire'
import Board from '../../components/Board'
import LoadingCentered from '../../components/LoadingCentered'
import { PageProps } from '../Router'
import ProjectsList from './ProjectsList'
import { TProject } from '../../hooks/useCardTree'

const Projects = ({ userRef }: PageProps) => {
  const firestore = useFirestore()
  const { projectId, cardId } = useParams()

  const projectRef = useMemo(() => {
    return projectId && doc(firestore, 'projects', projectId)
  }, [projectId, firestore])

  const cardRef = useMemo(() => {
    return cardId && projectRef ? doc(projectRef, 'cards', cardId) : null
  }, [cardId, projectRef])

  return projectId && projectRef ? (
    <BoardWrapper projectRef={projectRef} parentCardRef={cardRef} userRef={userRef} />
  ) : (
    <ProjectsList userRef={userRef} />
  )
}

const BoardWrapper = ({ projectRef, userRef, parentCardRef }: any) => {
  const { status: projectStatus, data: projectSnap } = useFirestoreDoc<TProject>(projectRef)
  return projectStatus === 'loading' ? (
    <LoadingCentered />
  ) : (
    <Board projectSnap={projectSnap} parentCardRef={parentCardRef} userRef={userRef} />
  )
}

export default memo(Projects)
