import {
  Alert,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import { DocumentReference, DocumentSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore'
import React, { useCallback } from 'react'
import { EditText, onSaveProps } from 'react-edit-text'
import 'react-edit-text/dist/index.css'
import EditMarkdown from '../EditMarkdown'
import { statusColor } from './BoardCard'
import DeleteIcon from '@mui/icons-material/Delete'
import ArchiveIcon from '@mui/icons-material/Archive'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import { TCard, TProject } from '../../hooks/useCardTree'
import CardParentSelector from './CardParentSelector'
import Dates from './Card/Dates'
import State from './Card/State'
import ExpiringCard from './Card/ExpiringCard'
import CardDetailsActionableItems from './Card/CardDetailsActionableItems'
import Metadata from './Card/Metadata'

type Props = {
  userRef: DocumentReference
  cardSnap: DocumentSnapshot<TCard>
  sx?: SxProps<Theme>
  compact: boolean
}

const CardDetails = ({ sx, cardSnap, userRef, compact = false }: Props) => {
  const handleEditTitle = useCallback(
    async (saveProps: onSaveProps) => {
      cardSnap.ref &&
        saveProps.value?.trim() &&
        (await updateDoc(cardSnap.ref, {
          title: saveProps.value?.trim(),
          lastUpdatedAt: serverTimestamp(),
          lastUpdatedBy: userRef,
        }))
      return
    },
    [cardSnap.ref, userRef],
  )

  const handleEditDescription = useCallback(
    async (value: string) => {
      cardSnap.ref &&
        (await updateDoc(cardSnap.ref, {
          description: value,
          lastUpdatedAt: serverTimestamp(),
          lastUpdatedBy: userRef,
        }))
      return
    },
    [cardSnap.ref, userRef],
  )

  const handleChangeParentRef = useCallback(
    async (value: { ref: DocumentReference<TCard>; label: string }) => {
      cardSnap.ref &&
        (await updateDoc(cardSnap.ref, {
          parentCardRef: value.ref,
          lastUpdatedAt: serverTimestamp(),
          lastUpdatedBy: userRef,
        }))
      return
    },
    [cardSnap.ref, userRef],
  )

  const handleDelete = useCallback(async () => {
    if (
      cardSnap?.data()?.softRemoved ||
      window.confirm('Are you sure you want to remove this card?')
    ) {
      cardSnap.ref &&
        (await updateDoc(cardSnap.ref, {
          softRemoved: !cardSnap?.data()?.softRemoved,
          removedAt: serverTimestamp(),
          removedBy: userRef,
        }))
    }
  }, [cardSnap, userRef])

  const handleArchive = useCallback(async () => {
    if (cardSnap.ref) {
      const newArchived = !cardSnap.data()?.archived

      await updateDoc(cardSnap.ref, {
        archived: newArchived,
        ...(cardSnap.data()?.archived
          ? {
              unarchivedAt: serverTimestamp(),
              unarchivedBy: userRef,
            }
          : {
              archivedAt: serverTimestamp(),
              archivedBy: userRef,
            }),
      })
    }
    return
  }, [cardSnap, userRef])

  return (
    <Paper
      sx={{
        ...sx,
        height: '100%',
        // border: 'solid 1px #ccc',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        borderTop: cardSnap.data()?.state
          ? `solid 8px ${statusColor(cardSnap.data()!.state, true)}`
          : 'none',
      }}
      elevation={4}
    >
      <Box
        sx={{
          padding: '10px',
          maxHeight: '87vh',
          height: '87vh',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
        key={cardSnap.ref?.path}
      >
        <CardParentSelector
          projectRef={cardSnap.ref.parent.parent as DocumentReference<TProject>}
          onChange={handleChangeParentRef}
          value={cardSnap.data()?.parentCardRef || null}
          sx={{ ml: 0.5 }}
        />
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Typography variant="h6" component="div">
            <EditText
              defaultValue={cardSnap?.data()?.title}
              onSave={handleEditTitle}
              style={{
                marginLeft: 2,
                marginTop: 1,
                overflow: 'clip',
              }}
            />
          </Typography>
          <Stack direction="row" spacing={1}>
            <ExpiringCard cardSnap={cardSnap} />
            <Tooltip arrow title={cardSnap.data()?.archived ? 'Unarchive' : 'Archive'}>
              <IconButton onClick={handleArchive} size="small">
                {cardSnap.data()?.archived ? (
                  <UnarchiveIcon fontSize="small" />
                ) : (
                  <ArchiveIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip arrow title={cardSnap.data()?.softRemoved ? 'Recover' : 'Delete'}>
              <IconButton onClick={handleDelete} size="small">
                {cardSnap.data()?.softRemoved ? (
                  <RestoreFromTrashIcon fontSize="small" />
                ) : (
                  <DeleteIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        {/* <Typography color="text.primary" variant="h6" noWrap></Typography> */}
        <Divider />
        {cardSnap.data()?.softRemoved && (
          <Alert sx={{ marginTop: 1 }} severity="error">
            This card is deleted
          </Alert>
        )}
        {cardSnap.data()?.archived && (
          <Alert sx={{ marginTop: 1 }} severity="warning">
            This card is archived
          </Alert>
        )}

        <Grid container>
          <Grid item xs={compact ? 12 : 9}>
            <EditMarkdown
              value={cardSnap?.data()?.description || ''}
              onEdit={handleEditDescription}
            />
            <React.Fragment>
              <Divider variant="middle" />
              <CardDetailsActionableItems cardSnap={cardSnap} userRef={userRef} />
            </React.Fragment>
          </Grid>
          <Grid item xs={compact ? 12 : 3}>
            <State cardSnap={cardSnap} />
            <Divider />
            <Dates cardSnap={cardSnap} />
            <Divider />
            <Metadata cardSnap={cardSnap} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default CardDetails
