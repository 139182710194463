import {
  Box,
  Typography,
  Tooltip,
  CircularProgress,
  Button,
  Stack,
  IconButton,
  Paper,
  Avatar,
  Divider,
  Badge,
  Chip,
} from '@mui/material'
import {
  collection,
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore'
import { memo, useCallback, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFirestoreCollection } from 'reactfire'
import BoardCard, { statusColor } from './BoardCard'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Draggable } from 'react-beautiful-dnd'
import { EditText, onSaveProps } from 'react-edit-text'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import CommentsIcon from './CommentsIcon'
import ArchiveIcon from '@mui/icons-material/Archive'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import InfoIcon from '@mui/icons-material/Info'
import { TCard } from '../../hooks/useCardTree'
import ExpiringCard from './Card/ExpiringCard'
import ActionableItems, { TActionableItem } from './ActionableItems'
import ChecklistIcon from '@mui/icons-material/Checklist'

type Props = {
  cardSnap: DocumentSnapshot<TCard>
  userRef: DocumentReference
  onToggleAddCardModal: (open: boolean, parentCards?: DocumentReference<TCard>[]) => void
  onToggleCardDetailsModal: (cardSnap?: DocumentSnapshot<TCard>) => void
  handlerProps: any
  showArchived: boolean
  collapseCards: boolean
  colDDProvider: any
  colDDSnapshot: any
}

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? 'grey' : 'inherit',
  overflow: 'scroll',
  height: 'calc(87vh - 138px)',
})

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // styles we need to apply on draggable items
  ...draggableStyle,
})
export const WIDTH_COLUMN = 300

const BoardColumn = ({
  cardSnap,
  userRef,
  onToggleAddCardModal,
  onToggleCardDetailsModal,
  handlerProps,
  showArchived,
  collapseCards,
  colDDProvider,
  colDDSnapshot,
}: Props) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [showActionableItems, setShowActionableItems] = useState(false)
  const cardsCollection = useMemo(() => {
    return collection(cardSnap.ref.parent.parent!, 'cards') as CollectionReference<TCard>
  }, [cardSnap.ref.parent])

  const cardsQuery = useMemo(() => {
    return query(
      cardsCollection,
      where('softRemoved', '==', false),
      where('parentCardRef', '==', cardSnap.ref),
    )
  }, [cardSnap.ref, cardsCollection])

  const { status: cardsStatus, data: cardsSnaps } = useFirestoreCollection(cardsQuery)

  const actionableItemsCollection = useMemo(
    () => collection(cardSnap.ref, 'actionableItems') as CollectionReference<TActionableItem>,
    [cardSnap],
  )
  const actionableItemsQuery = useMemo(() => {
    return query<TActionableItem>(
      actionableItemsCollection,
      where('softRemoved', '==', false),
      where('solved', '==', false),
    )
  }, [actionableItemsCollection])
  const { status: actionableItemsStatus, data: actionableItemsSnaps } =
    useFirestoreCollection(actionableItemsQuery)

  const cards = useMemo(() => {
    return cardsStatus === 'success' && cardsSnaps?.docs
      ? cardsSnaps?.docs
          ?.filter(cardSnap => {
            const priorities = searchParams.getAll('priority')
            if (priorities?.length) {
              if (
                ((typeof cardSnap.data()?.priority !== 'number' ||
                  cardSnap.data().priority! >= 6) &&
                  priorities.includes('NP')) ||
                (typeof cardSnap.data()?.priority === 'number' &&
                  priorities.map(Number).includes(cardSnap.data().priority!))
              ) {
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          })
          .filter(cardSnap => {
            const state = searchParams.getAll('state')
            if (state?.length) {
              if (state.includes(cardSnap.data().state)) {
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          })
          .sort((a, b) => (a.data()?.order ?? Infinity) - (b.data()?.order ?? Infinity))
      : []
  }, [cardsSnaps?.docs, cardsStatus, searchParams])

  const label = cardSnap.data()?.title || cardSnap.ref.id

  const handleClickPriority = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      const newPriority =
        typeof cardSnap.data()?.priority !== 'number' || cardSnap.data()?.priority! >= 6
          ? 0
          : cardSnap.data()?.priority! + 1
      await updateDoc(cardSnap.ref, {
        priority: newPriority,
      })
    },
    [cardSnap],
  )

  const handleChangeState = useCallback(
    async (state: TCard['state']) => {
      await updateDoc(cardSnap.ref, {
        lastUpdatedBy: userRef,
        state: state,
      })
    },
    [cardSnap.ref, userRef],
  )

  const handleArchive = useCallback(async () => {
    if (cardSnap.ref) {
      const newArchived = !cardSnap.data()?.archived

      await updateDoc(cardSnap.ref, {
        archived: newArchived,
        ...(cardSnap.data()?.archived
          ? {
              unarchivedAt: serverTimestamp(),
              unarchivedBy: userRef,
            }
          : {
              archivedAt: serverTimestamp(),
              archivedBy: userRef,
            }),
      })
    }
    return
  }, [cardSnap, userRef])

  const handleEditTitle = useCallback(
    async (saveProps: onSaveProps) => {
      cardSnap.ref &&
        saveProps.value &&
        (await updateDoc(cardSnap.ref, {
          title: saveProps.value?.trim(),
          lastUpdatedBy: userRef,
          lastUpdatedAt: serverTimestamp(),
        }))
      return
    },
    [cardSnap.ref, userRef],
  )

  const handleToggleShowActionableItems = useCallback(() => {
    setShowActionableItems(show => !show)
  }, [])

  return (
    <Paper
      sx={{
        height: '87vh',
        margin: '0 10px',
        width: `${WIDTH_COLUMN}px`,
        borderRadius: 3,
        overflow: 'hidden',
        pt: 0,
      }}
      elevation={0}
    >
      <Box
        sx={{
          paddingTop: 0,
          width: `100%`,
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{
            background: statusColor(cardSnap.data()!.state, true),
            margin: 0,
            padding: 0,
            height: '7px',
            width: '100%',
            '& .changeStateIcon': {
              visibility: 'hidden',
            },
            '&:hover': {
              width: 'auto',
              background: 'inherit',
              borderTop: `solid 2.5px ${statusColor(cardSnap.data()!.state, true)}`,
              '.changeStateIcon': {
                visibility: 'visible',
              },
            },
          }}
        >
          <Box mt={0}>
            <IconButton
              size="small"
              sx={{ color: statusColor('completed', true) }}
              className="changeStateIcon"
              onClick={() => handleChangeState('completed')}
            >
              <FiberManualRecordIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              sx={{ color: statusColor('warning', true) }}
              className="changeStateIcon"
              onClick={() => handleChangeState('warning')}
            >
              <FiberManualRecordIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              sx={{ color: statusColor('blocked', true) }}
              className="changeStateIcon"
              onClick={() => handleChangeState('blocked')}
            >
              <FiberManualRecordIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              sx={{ color: statusColor('inProgress', true) }}
              className="changeStateIcon"
              onClick={() => handleChangeState('inProgress')}
            >
              <FiberManualRecordIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              sx={{ color: statusColor('pending', true) }}
              className="changeStateIcon"
              onClick={() => handleChangeState('pending')}
            >
              <FiberManualRecordIcon fontSize="small" />
            </IconButton>
          </Box>
        </Stack>
        <Box
          pl={1}
          pr={1}
          sx={{
            borderRadius: 3,
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Box {...handlerProps} pt={'6px'}>
                <DragIndicatorIcon
                  fontSize="small"
                  sx={{
                    cursor: 'grab',
                  }}
                />
              </Box>
              <Tooltip title={label} arrow>
                <Typography
                  color="text.primary"
                  variant="subtitle2"
                  sx={{
                    fontWeight: 'bold',
                    // paddingLeft: 2,
                    // paddingRight: 1,
                    // marginTop: 1,
                    maxWidth: `${WIDTH_COLUMN - 120}px`,
                    width: `100%`,
                  }}
                  overflow={'clip'}
                  noWrap
                >
                  <EditText defaultValue={cardSnap?.data()?.title} onSave={handleEditTitle} />
                </Typography>
              </Tooltip>
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Chip
                size="small"
                label={cardsStatus === 'loading' ? '-' : cards?.length || '0'}
                sx={{ margin: 0, mr: -0.5 }}
              />
              <ExpiringCard cardSnap={cardSnap} />
              <IconButton
                size="small"
                onClick={() =>
                  navigate(
                    `/projects/${cardSnap.ref.parent.parent!.id}/cards/${cardSnap.ref?.id || ''}`,
                  )
                }
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      </Box>

      <div
        style={{
          padding: '8px',
          paddingTop: 0,

          // maxHeight: 'calc(100vh - 158.5px)',
          // height: '100%',
          overflowX: 'hidden',
          overflowY: 'clip',
        }}
      >
        {cardsStatus === 'loading' ? (
          <CircularProgress />
        ) : (
          <>
            <Divider
              variant="fullWidth"
              sx={{
                mb: 1,
              }}
            />
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={0}
              sx={{
                padding: '0px 8px 0px 0px',
                width: '100%',
              }}
            >
              <Tooltip title="See details">
                <IconButton size="small" onClick={() => onToggleCardDetailsModal(cardSnap)}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              {/* <Chip
                size="small"
                label={cardsStatus === 'loading' ? '-' : cards?.length || '0'}
                sx={{ margin: 1 }}
              /> */}
              <CommentsIcon cardRef={cardSnap.ref} userRef={userRef} />
              <Tooltip title={cardSnap.data()?.archived ? 'Unarchive' : 'Archive'}>
                <IconButton onClick={handleArchive}>
                  {cardSnap.data()?.archived ? (
                    <UnarchiveIcon fontSize="small" />
                  ) : (
                    <ArchiveIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip
                title={`Priority: ${
                  typeof cardSnap.data()?.priority !== 'number' || cardSnap.data()?.priority === 6
                    ? 'No Priority'
                    : `P${cardSnap.data()?.priority}`
                }`}
              >
                <IconButton size="small" onClick={handleClickPriority}>
                  {typeof cardSnap.data()?.priority !== 'number' || cardSnap.data()?.priority === 6
                    ? 'NP'
                    : `P${cardSnap.data()?.priority}`}
                </IconButton>
              </Tooltip>
              <Tooltip
                title={showActionableItems ? `Hide actionable items` : `Show actionable items`}
              >
                <IconButton size="small" onClick={handleToggleShowActionableItems}>
                  <Badge
                    // variant="dot"
                    badgeContent={
                      actionableItemsStatus === 'success' ? actionableItemsSnaps?.size : '0'
                    }
                    color="secondary"
                  >
                    <ChecklistIcon fontSize="small" />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Avatar
                sx={{ width: 24, height: 24 }}
                title={'Luciano Ganga'}
                alt={'LG'}
                // src={user.avatarUrl}
              />
            </Stack>

            {showActionableItems && (
              <Box sx={{ overflow: 'scroll', maxHeight: '108px', mr: 1, ml: 1 }}>
                <ActionableItems
                  cardSnap={cardSnap}
                  userRef={userRef}
                  showAddButton
                  showHelperText={false}
                />
              </Box>
            )}
            {showActionableItems && <Divider sx={{ mt: 1, mb: 1 }} />}
            <div
              ref={colDDProvider.innerRef}
              style={getListStyle(colDDSnapshot.isDraggingOver)}
              {...colDDProvider.droppableProps}
            >
              {cards?.map((cardSnap, index) => {
                return (
                  <Draggable key={cardSnap.ref.id} draggableId={cardSnap.ref.id} index={index}>
                    {(provided, snapshot) => (
                      <Box
                        sx={{
                          display: showArchived || !cardSnap?.data()?.archived ? 'inherit' : 'none',
                        }}
                        ref={provided.innerRef}
                        component="div"
                        {...provided.draggableProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <BoardCard
                          userRef={userRef}
                          key={cards[index].ref.id}
                          cardSnap={cards[index]}
                          index={index}
                          onToggleCardDetailsModal={onToggleCardDetailsModal}
                          handlerProps={provided.dragHandleProps}
                          collapsed={collapseCards}
                        />
                      </Box>
                    )}
                  </Draggable>
                )
              })}
              {colDDProvider.placeholder}
            </div>

            <Button
              fullWidth
              onClick={() => onToggleAddCardModal(true, [cardSnap.ref])}
              size="small"
              sx={{
                border: '1px dashed #ccc',
                width: 275,
                margin: '5px',
                textAlign: 'center',
              }}
            >
              Add card
            </Button>
          </>
        )}
      </div>
    </Paper>
  )
}

export default memo(BoardColumn)
