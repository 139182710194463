import { useCallback } from 'react'

import {
  addDoc,
  collection,
  CollectionReference,
  DocumentReference,
  serverTimestamp,
} from 'firebase/firestore'

import InputBox from './InputBox'
import { TConversation, TMessage } from '../../Conversation'

type Props = {
  conversationRef: DocumentReference<TConversation>
  userRef: DocumentReference
}

const ChatInput = ({ conversationRef, userRef }: Props) => {
  const conversationMessagesCollectionRef = collection(
    conversationRef,
    'messages',
  ) as CollectionReference<TMessage>

  const handleSendMessage = useCallback(
    async (text: string) => {
      await addDoc(conversationMessagesCollectionRef, {
        type: 'text',
        sentBy: userRef,
        content: { text },
        sentAt: serverTimestamp(),
      })
    },
    [conversationMessagesCollectionRef, userRef],
  )

  return <InputBox isLoading={false} onSubmit={handleSendMessage} />
}

export default ChatInput
