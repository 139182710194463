import { Box, CardContent, IconButton, Paper, Typography } from '@mui/material'
import { DocumentSnapshot } from 'firebase/firestore'
import { TCard } from '../../../hooks/useCardTree'
import { statusColor } from '../BoardCard'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Stack } from '@mui/system'

type Props = {
  cardSnap: DocumentSnapshot<TCard>
}
const State = ({ cardSnap }: Props) => {
  return (
    <Paper sx={{ width: '100%', p: 1, pl: 2, pr: 2, background: 'transparent' }} elevation={0}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
          State:
        </Typography>
        {cardSnap?.data() && (
          <IconButton
            size="small"
            sx={{ color: statusColor(cardSnap.data()!.state, true) }}
            className="changeStateIcon"
          >
            <FiberManualRecordIcon fontSize="small" />
          </IconButton>
        )}
      </Stack>
    </Paper>
  )
}

export default State
