import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import {
  DocumentReference,
  DocumentSnapshot,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { useCallback, useState } from 'react'
import useUsers from '../../hooks/useUsers'

function getInitials(input: string): string {
  // Split the string into an array of words
  const words = input.split(' ')

  // Get the first two words
  const firstWord = words[0] || ''
  const secondWord = words[1] || ''

  // Extract the initials from the first two words
  const firstInitial = firstWord.charAt(0).toUpperCase()
  const secondInitial = secondWord.charAt(0).toUpperCase()

  // Return the initials
  return firstInitial + secondInitial
}

type Props = {
  projectSnap: DocumentSnapshot
  cardSnap: DocumentSnapshot
  userRef: DocumentReference
  onSave?: () => void
}

const ShareBoard = ({ projectSnap, cardSnap, userRef, onSave }: Props) => {
  const [value, setValue] = useState<any[]>(projectSnap.data()?.authorizedUsers || [])
  const [isLoading, setIsLoading] = useState(false)
  const { data: users } = useUsers()

  const handleSave = useCallback(async () => {
    const sharedProjectRef = doc(projectSnap.ref.parent, cardSnap?.ref?.id || projectSnap.id)
    const sharedProjectSnap = await getDoc(sharedProjectRef)
    setIsLoading(true)
    if (sharedProjectSnap.exists()) {
      await updateDoc(sharedProjectRef, {
        updatedAt: serverTimestamp(),
        updatedBy: userRef,
        projectRef: projectSnap.ref,
        authorizedUsers: value.map(value => value.ref),
      })
    } else {
      const newProject = {
        name: cardSnap.data()?.title,
        type: 'shared',
        softRemoved: false,
        createdAt: serverTimestamp(),
        createdBy: userRef,
        projectRef: projectSnap.ref,
        authorizedUsers: value.map(value => value.ref),
      }

      await setDoc(sharedProjectRef, newProject, { merge: true })
    }
    setIsLoading(false)
    onSave?.()
  }, [cardSnap, onSave, projectSnap.id, projectSnap.ref, userRef, value])

  return (
    <Box height={350} component={Paper} p={2}>
      <Typography variant="h6">Share board</Typography>
      <Typography variant="body2">
        Share the current board and the lower levels with someone
      </Typography>
      <Autocomplete
        multiple
        options={users || []}
        getOptionLabel={user => `${user.data()?.displayName}` || '-'}
        // getOptionLabel={user => `${user.data()?.displayName} (${user.data()?.email})` || '-'}
        // renderTags={(users: any) =>
        //   users.map((user: any) => (
        //     <Chip
        //       avatar={
        //         user.photoURL ? (
        //           <Avatar alt={user.displayName} src={user.photoURL} />
        //         ) : (
        //           <Avatar>{getInitials(user.displayName)}</Avatar>
        //         )
        //       }
        //       label={user.displayName}
        //       variant="outlined"
        //     />
        //   ))
        // }
        // value={value}
        onChange={(event: any, newValue: any[]) => {
          setValue(newValue)
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label="Invite users"
            // placeholder="Invite users"
          />
        )}
      />
      <Button
        sx={{ m: 2, float: 'right' }}
        variant="contained"
        onClick={handleSave}
        disabled={isLoading}
      >
        Save
      </Button>{' '}
    </Box>
  )
}

export default ShareBoard
