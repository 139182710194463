import { useMemo } from 'react'
import MessagesList from './MessagesList'
import { useFirestoreCollection } from 'reactfire'
import {
  collection,
  CollectionReference,
  DocumentReference,
  orderBy,
  query,
} from 'firebase/firestore'
import { TConversation, TMessage } from '../../Conversation'

type Props = {
  conversationRef: DocumentReference<TConversation>
}
const ChatMessages = ({ conversationRef }: Props) => {
  const conversationMessagesCollectionRef = collection(
    conversationRef,
    'messages',
  ) as CollectionReference<TMessage>

  const messagesQuery = useMemo(
    () => query<TMessage>(conversationMessagesCollectionRef, orderBy('sentAt', 'asc')),
    [conversationMessagesCollectionRef],
  )

  const { status: messagesStatus, data: messagesSnaps } =
    useFirestoreCollection<TMessage>(messagesQuery)

  return <MessagesList messagesSnaps={messagesSnaps?.docs || []} messagesStatus={messagesStatus} />
}

export default ChatMessages
