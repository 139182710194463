import { Paper, Typography } from '@mui/material'
import { DocumentReference, DocumentSnapshot } from 'firebase/firestore'
import { TCard } from '../../../hooks/useCardTree'
import ActionableItems from '../ActionableItems'

type Props = {
  cardSnap: DocumentSnapshot<TCard>
  userRef: DocumentReference
}
const CardDetailsActionableItems = ({ cardSnap, userRef }: Props) => {
  return (
    <Paper sx={{ width: '100%', p: 1, pl: 2, pr: 2, background: 'transparent' }} elevation={0}>
      <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
        Actionable Items
      </Typography>
      {cardSnap?.data() && (
        <ActionableItems
          showAddButton
          showSolved
          cardSnap={cardSnap}
          userRef={userRef}
          showDragHandler
          showDelete
          showEdit
        />
      )}
    </Paper>
  )
}

export default CardDetailsActionableItems
