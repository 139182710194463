import React, { useMemo } from 'react'
import { Box, Paper, Stack, Typography } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { DocumentSnapshot } from 'firebase/firestore'

import ChatIcon from './ChatIcon'
import MessageIconContainer from './MessageIconContainer'
import { TMessage } from '../../Conversation'

type Props = {
  messageSnap: DocumentSnapshot<TMessage>
}

function replaceWithLink(text: string) {
  // Put the URL to variable $1 after visiting the URL
  const Rexp = /((http|https|ftp):\/\/[\w?=&./-;#~%-]+(?![\w\s?&./;#~%"=-]*>))/g

  // Replace the RegExp content by HTML element
  return text.replace(Rexp, "<a href='$1' target='_blank'>$1</a>")
}

const Message = ({ messageSnap }: Props) => {
  const messageData = messageSnap.data()!

  const sentBy = useMemo(() => {
    return !['system', 'assistant'].includes(messageData!.sentBy.id)
      ? 'user'
      : messageData!.sentBy.id
  }, [messageData])

  return (
    <Stack
      direction={sentBy === 'user' ? 'row-reverse' : 'row'}
      justifyContent="flex-start"
      alignItems="center"
      sx={{ width: '100%' }}
      component={['system', 'model'].includes(sentBy) ? Paper : 'div'}
    >
      <MessageIconContainer>
        {sentBy === 'user' ? <PersonIcon color="secondary" /> : <ChatIcon />}
      </MessageIconContainer>
      <Box p={2}>
        <Typography
          variant="body1"
          whiteSpace="pre-line"
          sx={{
            color: 'text.primary',
            a: {
              color: 'text.primary',
            },
            wordBreak: 'break-word',
          }}
        >
          {messageData.type === 'text' || messageData.type === 'image' ? (
            messageData.content.text
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: replaceWithLink(messageData.content.text ?? '') }}
            />
          )}
        </Typography>
      </Box>
    </Stack>
  )
}

export default Message
