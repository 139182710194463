import React, { useEffect, useMemo, useState } from 'react'
import { Avatar, Box, Paper, Skeleton, Stack, Typography } from '@mui/material'
import Message from './Message'
import { DocumentSnapshot } from 'firebase/firestore'

import LoadingAnimation from './LoadingAnimation'
import ChatIcon from './ChatIcon'
import MessageIconContainer from './MessageIconContainer'
import { TMessage } from '../../Conversation'

const SkeletonMessage = () => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={0}
      sx={{ width: '100%', marginTop: 2, marginBottom: 2 }}
    >
      <Box p={0}>
        <Box width={'100px'} ml={2}>
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        </Box>
      </Box>
      <Box width="100%">
        <Skeleton variant="rectangular" width="95%" />
      </Box>
    </Stack>
  )
}

type Props = {
  messagesSnaps: DocumentSnapshot<TMessage>[]
  messagesStatus: 'loading' | 'success' | 'error'
}
const MessagesList = ({ messagesSnaps, messagesStatus }: Props) => {
  const [isWaitingReply, setIsWaitingReply] = useState(false)
  const sortedMessages = useMemo(() => messagesSnaps.slice(0).reverse(), [messagesSnaps])

  // If the last message is from the user, we need to wait for the reply
  useEffect(() => {
    const lastMessage = sortedMessages[0]
    if (lastMessage) {
      setIsWaitingReply(!['system', 'assistant'].includes(lastMessage.data()!.sentBy.id))
    }
  }, [sortedMessages])

  return (
    <Box
      sx={{
        padding: 2,
        position: 'relative',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column-reverse',
        flex: '1',
        gap: 2,
      }}
    >
      {messagesStatus === 'success' && isWaitingReply && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
          sx={{
            width: '100%',
            bottom: '10px',
          }}
          component={Paper}
        >
          <MessageIconContainer>
            <ChatIcon />
          </MessageIconContainer>
          <Box p={2} sx={{ color: 'text.primary', display: 'flex', gap: 1.5 }}>
            <Typography variant="body1" whiteSpace="pre-line">
              Typing...
            </Typography>
            <LoadingAnimation></LoadingAnimation>
          </Box>
        </Stack>
      )}
      {messagesStatus === 'loading' && [<SkeletonMessage key="1" />, <SkeletonMessage key="2" />]}
      {messagesStatus === 'success' &&
        sortedMessages.map(message => <Message key={message.id} messageSnap={message} />)}
    </Box>
  )
}

export default MessagesList
