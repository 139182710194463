import { IconButton, Tooltip } from '@mui/material'
import { DocumentSnapshot } from 'firebase/firestore'
import { TCard } from '../../../hooks/useCardTree'
import { keyframes } from '@emotion/react'

import dayjs from 'dayjs'
import { useMemo } from 'react'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'

const vibratingClockAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-1px) rotate(8deg);
  }
  50% {
    transform: translateX(0) rotate(-8deg);
  }
  75% {
    transform: translateX(1px) rotate(8deg);
  }
  100% {
    transform: translateX(0);
  }
`

const vibratingClockStyles = {
  animation: `${vibratingClockAnimation} 0.2s infinite`,
}

type Props = {
  cardSnap: DocumentSnapshot<TCard>
}
const ExpiringCard = ({ cardSnap }: Props) => {
  const expirationInDays = useMemo(() => {
    const endDate = cardSnap.data()?.dates?.end
    if (endDate) {
      // Convert Firestore Timestamp to dayjs object
      const dateValue = dayjs(endDate.toDate())

      // Check if the date from Firestore is within the next 7 days
      return dateValue.diff(dayjs(), 'day')
    }
  }, [cardSnap])

  return typeof expirationInDays === 'number' ? (
    <Tooltip
      title={
        expirationInDays < 0
          ? 'This card is expired!'
          : expirationInDays === 0
          ? 'This card expires today!'
          : expirationInDays > 1
          ? `This card expires in ${expirationInDays} days`
          : `This card expires tomorrow!`
      }
    >
      <IconButton size="small">
        <AccessAlarmIcon
          sx={expirationInDays >= 0 && expirationInDays < 2 ? vibratingClockStyles : {}}
          fontSize="small"
          color={
            expirationInDays > 7
              ? 'success'
              : expirationInDays > 3
              ? 'info'
              : expirationInDays > 1
              ? 'warning'
              : expirationInDays === 1
              ? 'error'
              : 'error'
          }
        />
      </IconButton>
    </Tooltip>
  ) : (
    <></>
  )
}

export default ExpiringCard
