import { useCallback } from 'react'
import { useFirestore, useFirestoreCollection } from 'reactfire'
import { doc, query, where } from 'firebase/firestore'
import useFirestoreCollectionRef from './useFirestoreCollectionRef'

const useUsers = () => {
  const firestore = useFirestore()
  const usersCollection = useFirestoreCollectionRef<Record<string, any>>('users')

  const usersQuery = query(usersCollection, where('softRemoved', '==', false))

  const { status: usersQueryStatus, data: usersQueryResults } = useFirestoreCollection(usersQuery)

  const getUserRef = (userId: string) => doc(firestore, 'users', userId)

  const getUserById = useCallback(
    (userId?: string) => {
      return userId && usersQueryStatus === 'loading'
        ? { displayName: 'loading user data...', avatarUrl: '' }
        : usersQueryResults?.docs?.find(u => u.id === userId) || {
            id: userId,
            path: `users/${userId}`,
            ref: doc(firestore, `users/${userId}`),
            displayName: userId,
            avatarUrl: '',
            email: '',
          }
    },
    [firestore, usersQueryResults?.docs, usersQueryStatus],
  )

  return {
    status: usersQueryStatus,
    data: usersQueryResults?.docs,
    getUserById,
    getUserRef,
  }
}

export default useUsers
