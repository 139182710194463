import React, { useCallback, useMemo, useState } from 'react'
import { Autocomplete, Box, SxProps, TextField, Typography } from '@mui/material'
import { DocumentReference } from 'firebase/firestore'
import useCardTree, { ICardTree, TCard, TProject } from '../../hooks/useCardTree'

type Props = {
  projectRef: DocumentReference<TProject>
  value: TCard['parentCardRef']
  onChange: (value: { ref: DocumentReference<TCard>; label: string }) => void
  sx?: SxProps
}

const CardParentSelector = ({ projectRef, value, onChange, sx }: Props) => {
  const [editMode, setEditMode] = useState(false)
  const { status: cardTreeStatus, data: tree } = useCardTree(projectRef)

  const parentRefSelectOptions = useMemo(() => {
    interface ResultObject {
      ref: TCard['parentCardRef']
      label: string
    }

    function generateArrayWithIdAndSorting(
      nestedArray: ICardTree[],
      parentLabels: string[] = [],
    ): ResultObject[] {
      let result: ResultObject[] = []

      const sortedNestedArray = nestedArray.sort((a, b) => a.order - b.order)

      for (const nestedObj of sortedNestedArray) {
        const currentLabels = [...parentLabels, nestedObj.title]
        const resultObj: ResultObject = {
          ref: nestedObj.ref,
          label: currentLabels?.join(' → '),
        }
        result.push(resultObj)

        if (nestedObj.children.length > 0) {
          const childResult = generateArrayWithIdAndSorting(nestedObj.children, currentLabels)
          result = [...result, ...childResult]
        }
      }

      return result
    }
    if (cardTreeStatus === 'success') {
      return generateArrayWithIdAndSorting(tree)
    }
    return []
  }, [cardTreeStatus, tree])

  const handleChange = useCallback(
    async (event: React.SyntheticEvent, value: any) => {
      await onChange?.(value)
      setEditMode(false)
    },
    [onChange],
  )

  const selectedOption = useMemo(() => {
    return value && parentRefSelectOptions
      ? {
          ref: value,
          label:
            value === null
              ? 'Root'
              : parentRefSelectOptions.find(c => c?.ref?.path === value?.path)?.label || 'Unknown',
        }
      : { ref: null, label: 'Root' }
  }, [parentRefSelectOptions, value])

  return (
    <Box sx={{ ...sx, cursor: 'pointer' }}>
      {editMode ? (
        <Autocomplete
          disablePortal
          value={selectedOption}
          onBlur={() => setEditMode(false)}
          options={[
            {
              ref: null,
              label: 'Root',
            },
            ...parentRefSelectOptions,
          ]}
          isOptionEqualToValue={(option, value) => option?.ref?.path === value?.ref?.path}
          onChange={handleChange}
          fullWidth
          renderInput={params => <TextField {...params} label="Parent" />}
        />
      ) : (
        <Typography onClick={() => setEditMode(true)} variant="caption">
          {'>'} <strong>{selectedOption?.label || 'Root'}</strong>
        </Typography>
      )}
    </Box>
  )
}

export default CardParentSelector
