import {
  Button,
  Stack,
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Divider,
} from '@mui/material'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import Header from './components/Header'
import ChatInput from './components/Chat/ChatInput'
import ChatMessages from './components/Chat/ChatMessages'
import { useCallback, useMemo, useState } from 'react'
import {
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  addDoc,
  query,
  serverTimestamp,
  orderBy,
  updateDoc,
  where,
} from 'firebase/firestore'
import useFirestoreCollectionRef from '../../hooks/useFirestoreCollectionRef'
import { useFirestoreCollection } from 'reactfire'
import LoadingCentered from '../LoadingCentered'

export type TConversation = {
  title: string
  softRemoved: boolean
  createdAt: Timestamp
  createdBy: DocumentReference
  removedAt?: Timestamp
  removedBy?: DocumentReference
}
export type TMessage = {
  id?: string
  type: 'text' | 'html' | 'image'
  content: { text: string }
  sentBy: DocumentReference
  sentAt?: Date
}

type Props = {
  userRef: DocumentReference
  onClose: () => void
}
const Conversation = ({ onClose, userRef }: Props) => {
  const [conversationRef, setConversationRef] = useState<DocumentReference<TConversation> | null>(
    null,
  )

  const conversationsCollection = useFirestoreCollectionRef<TConversation>('conversations')
  const conversationsQuery = useMemo(() => {
    return query(
      conversationsCollection,
      where('softRemoved', '==', false),
      where('createdBy', '==', userRef),
      orderBy('createdAt', 'asc'),
    )
  }, [conversationsCollection, userRef])
  const { status: conversationsStatus, data: conversationsSnaps } =
    useFirestoreCollection(conversationsQuery)

  const handleCreateConversation = useCallback(async () => {
    const newConverationnRef = await addDoc(conversationsCollection, {
      title: '',
      softRemoved: false,
      createdAt: serverTimestamp(),
      createdBy: userRef,
    })
    setConversationRef(newConverationnRef)
  }, [conversationsCollection, userRef])

  const handleDeleteConversation = useCallback(
    async (conversationSnap: DocumentSnapshot<TConversation>) => {
      if (window.confirm('Are you sure you want to remove this conversation?')) {
        await updateDoc(conversationSnap.ref, {
          softRemoved: true,
          removedAt: serverTimestamp(),
          removedBy: userRef,
        })
      }
    },
    [userRef],
  )

  return (
    <Container disableGutters sx={{ height: '100vh' }}>
      <Stack direction="column" justifyItems="space-between" sx={{ height: '100%' }}>
        <Stack>
          <Header>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              {conversationRef ? (
                <Button
                  sx={{ color: 'primary.contrastText' }}
                  onClick={() => setConversationRef(null)}
                  startIcon={<ArrowBackIcon fontSize="small" />}
                >
                  Back
                </Button>
              ) : (
                <Typography>Conversations</Typography>
              )}
              <IconButton onClick={() => onClose()} size="small">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Stack>
          </Header>
          <Divider />
        </Stack>
        <Stack direction="column" sx={{ flex: '1', overflowY: 'scroll' }}>
          {conversationRef ? (
            <ChatMessages conversationRef={conversationRef} />
          ) : conversationsStatus === 'success' && !!conversationsSnaps.size ? (
            <List>
              {conversationsSnaps.docs?.map((conversationSnap, i) => {
                return (
                  <ListItem
                    disablePadding
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDeleteConversation(conversationSnap)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    }
                  >
                    <ListItemButton onClick={() => setConversationRef(conversationSnap.ref)}>
                      <ListItemText
                        primary={conversationSnap.data()?.title || `Conversation #${i + 1}`}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          ) : conversationsStatus === 'success' && !conversationsSnaps.size ? (
            <>
              <Typography variant="caption" textAlign="center" p={2}>
                No conversations started yet
              </Typography>
            </>
          ) : (
            <LoadingCentered />
          )}
        </Stack>
        {!conversationRef && (
          <Typography variant="caption" textAlign="center" p={2}>
            The AI assistant is a feature in progress. The interactions are limited to an assistant
            that has no understanding of your data in Managi. <br />
            <br />
            We're working on adding very interesting features here! So, stay tuned!
          </Typography>
        )}
        {!conversationRef && (
          <Button
            color="primary"
            variant="contained"
            sx={{ m: 2 }}
            onClick={() => handleCreateConversation()}
            endIcon={<AddIcon fontSize="small" />}
          >
            Start conversation
          </Button>
        )}
        <Stack>
          {conversationRef ? (
            <Box mb={'32px'}>
              <ChatInput conversationRef={conversationRef} userRef={userRef} />
            </Box>
          ) : null}
        </Stack>
      </Stack>
    </Container>
  )
}

export default Conversation
