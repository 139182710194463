import { useCallback, useState } from 'react'
import Link, { LinkProps } from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom'
import { DocumentReference, DocumentSnapshot } from 'firebase/firestore'
import { Box, Divider, IconButton, Modal, Skeleton, Tooltip } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ShareIcon from '@mui/icons-material/Share'
import ShareBoard from './ShareBoard'
import { Stack } from '@mui/system'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import TableRowsIcon from '@mui/icons-material/TableRows'
import TocIcon from '@mui/icons-material/Toc'
import FilterListIcon from '@mui/icons-material/FilterList'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
// import FaceIcon from '@mui/icons-material/Face'
import IconMenu from '../IconMenu'
import { statusColor } from './BoardCard'

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />
}

type Props = {
  projectSnap: DocumentSnapshot
  cardsSnaps?: DocumentSnapshot[]
  userRef: DocumentReference
  showArchived: boolean
  collapseCards: boolean
  onToggleShowArchived: () => void
  onToggleCollapseCards: (cardSnap?: DocumentSnapshot) => void
}
const BoardBreadcrumbs = ({
  projectSnap,
  cardsSnaps,
  userRef,
  showArchived,
  collapseCards,
  onToggleShowArchived,
  onToggleCollapseCards,
}: Props) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [openShareBoardModal, setOpenShareBoardModal] = useState(false)
  const [showFilters, setShowFilters] = useState(!!Array.from(searchParams.keys()).length)

  const handleNavigateBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <Box display="flex" pt={1} mb={1}>
      {/* MODAL WITH DETAILS */}
      <Modal
        open={!!openShareBoardModal}
        onClose={() => setOpenShareBoardModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          {openShareBoardModal && (
            <ShareBoard
              projectSnap={projectSnap}
              cardSnap={cardsSnaps?.[cardsSnaps.length - 1]!}
              userRef={userRef}
              onSave={() => setOpenShareBoardModal(false)}
            />
          )}
        </Box>
      </Modal>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          ml: 0,
        }}
      >
        <Tooltip arrow title="Go back">
          <IconButton onClick={handleNavigateBack} size="small">
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {/* <Tooltip arrow title="Go to all projects">
        <IconButton
          onClick={() => {
            navigate('/projects')
          }}
        >
          <FolderCopyIcon />
        </IconButton>
      </Tooltip> */}

        <LinkRouter
          underline="hover"
          color="inherit"
          to={`/projects/${projectSnap.id}`}
          variant="subtitle2"
        >
          <strong>{projectSnap.data()?.name || 'Root'}</strong>
        </LinkRouter>
        {!cardsSnaps && <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="100px" />}
        {cardsSnaps?.map((cardSnap, index) => {
          const last = index === cardsSnaps.length - 1
          const to = `/projects/${projectSnap.id}/cards/${cardSnap.id}`

          return last ? (
            <Typography color="text.primary" key={to} variant="subtitle2">
              {cardSnap.data()!.title}
            </Typography>
          ) : (
            <LinkRouter underline="hover" color="inherit" to={to} key={to} variant="subtitle2">
              {cardSnap.data()!.title}
            </LinkRouter>
          )
        })}
      </Breadcrumbs>
      <Box sx={{ flexGrow: 1 }}></Box>
      <Stack direction="row" spacing={1} sx={{ height: '28px' }}>
        <Stack
          direction="row"
          sx={{ ...(showFilters ? { background: '#444a55' } : {}), pl: 1, pr: 1, borderRadius: 5 }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              transition: 'width 0.1s ease-in-out',
              ...(showFilters
                ? { width: '100px', opacity: 1, display: 'contents' }
                : { width: 0, opacity: 0 }),
            }}
          >
            <IconMenu
              keyName="priority"
              Icon={<>P</>}
              options={[
                { value: '0', Icon: <>P0</> },
                { value: '1', Icon: <>P1</> },
                { value: '2', Icon: <>P2</> },
                { value: '3', Icon: <>P3</> },
                { value: '4', Icon: <>P4</> },
                { value: '5', Icon: <>P5</> },
                { value: 'NP', Icon: <>NP</> },
              ]}
            />
            <IconMenu
              keyName="state"
              Icon={<FiberManualRecordIcon fontSize="small" />}
              options={[
                {
                  value: 'completed',
                  Icon: (
                    <IconButton size="small" sx={{ color: statusColor('completed', true) }}>
                      <FiberManualRecordIcon />
                    </IconButton>
                  ),
                },
                {
                  value: 'warning',
                  Icon: (
                    <IconButton size="small" sx={{ color: statusColor('warning', true) }}>
                      <FiberManualRecordIcon />
                    </IconButton>
                  ),
                },
                {
                  value: 'blocked',
                  Icon: (
                    <IconButton size="small" sx={{ color: statusColor('blocked', true) }}>
                      <FiberManualRecordIcon />
                    </IconButton>
                  ),
                },
                {
                  value: 'inProgress',
                  Icon: (
                    <IconButton size="small" sx={{ color: statusColor('inProgress', true) }}>
                      <FiberManualRecordIcon />
                    </IconButton>
                  ),
                },
                {
                  value: 'pending',
                  Icon: (
                    <IconButton size="small" sx={{ color: statusColor('pending', true) }}>
                      <FiberManualRecordIcon />
                    </IconButton>
                  ),
                },
              ]}
            />
            {/* <IconMenu keyName="user" Icon={<FaceIcon fontSize="small" />} options={[]} /> */}
          </Stack>
          <Tooltip arrow title="Filters">
            <IconButton
              size="small"
              onClick={() => setShowFilters(show => !show)}
              sx={{
                marginLeft: 1,
              }}
            >
              {showFilters ? (
                <FilterListOffIcon fontSize="small" />
              ) : (
                <FilterListIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Stack>
        <Divider orientation="vertical" variant="inset" />
        <Tooltip arrow title={showArchived ? 'Hide archived' : 'Show archived'}>
          <IconButton onClick={() => onToggleShowArchived?.()} size="small">
            {showArchived ? (
              <VisibilityOffIcon fontSize="small" />
            ) : (
              <VisibilityIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip arrow title={collapseCards ? 'Expand cards' : 'Collapse cards'}>
          <IconButton onClick={() => onToggleCollapseCards?.()} size="small">
            {collapseCards ? <TableRowsIcon fontSize="small" /> : <TocIcon fontSize="small" />}
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" variant="inset" />
        <Tooltip arrow title="Share board">
          <IconButton onClick={() => setOpenShareBoardModal(true)} size="small">
            <ShareIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  )
}

export default BoardBreadcrumbs
