import React, { useCallback, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Avatar, Stack, useTheme } from '@mui/material'
import { useFirestoreCollection } from 'reactfire'
import {
  collection,
  query,
  where,
  orderBy,
  addDoc,
  serverTimestamp,
  DocumentReference,
  Timestamp,
  CollectionReference,
} from 'firebase/firestore'
import LoadingCentered from '../LoadingCentered'

interface CommentsBoxProps {
  cardRef: DocumentReference
  userRef: DocumentReference
}

type TComment = {
  text: string
  softRemoved: boolean
  createdAt: Timestamp
  createdBy: DocumentReference
}

const CommentsBox: React.FC<CommentsBoxProps> = ({ cardRef, userRef }) => {
  const [content, setContent] = useState('')
  const theme = useTheme()

  const commentsCollection = useMemo(
    () => collection(cardRef, 'comments') as CollectionReference<TComment>,
    [cardRef],
  )
  const commentsQuery = useMemo(() => {
    return query(commentsCollection, where('softRemoved', '==', false), orderBy('createdAt', 'asc'))
  }, [commentsCollection])
  const { status: commentsStatus, data: commentsSnaps } = useFirestoreCollection(commentsQuery)
  const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value)
  }

  const handleAddComment = useCallback(async () => {
    if (!content?.trim()) {
      return
    }
    await addDoc(commentsCollection, {
      text: content?.trim(),
      softRemoved: false,
      createdAt: serverTimestamp(),
      createdBy: userRef,
    })
    setContent('')
  }, [commentsCollection, content, userRef])

  return (
    <Box sx={{ width: 400, height: 500 }}>
      <Box sx={{ height: 415, width: '100%', overflow: 'scroll' }}>
        {commentsStatus === 'loading' ? (
          <LoadingCentered />
        ) : (
          commentsSnaps.docs?.map(comment => (
            <Stack
              key={comment.id}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{
                marginTop: 1,
                padding: 1,
                border: ` solid ${theme.palette.background.default} 1px`,
              }}
            >
              <Avatar
                sx={{ width: 24, height: 24 }}
                title={comment.data()?.createdBy?.path}
                alt={'LG'}
                // src={user.avatarUrl}
              />
              <Typography variant="body1">{comment.data()?.text}</Typography>
            </Stack>
          ))
        )}
      </Box>
      <Stack direction="row" sx={{ mt: 2 }} spacing={1}>
        <TextField
          label="Comment"
          value={content}
          onChange={handleContentChange}
          variant="outlined"
          size="small"
          fullWidth
          multiline
          rows={2}
        />
        <Button variant="contained" onClick={handleAddComment} size="small">
          Add
        </Button>
      </Stack>
    </Box>
  )
}

export default CommentsBox
