import { Paper, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DocumentSnapshot, Timestamp, updateDoc } from 'firebase/firestore'
import { TCard } from '../../../hooks/useCardTree'
import dayjs from 'dayjs'
import { useCallback } from 'react'

type Props = {
  cardSnap: DocumentSnapshot<TCard>
}
const Dates = ({ cardSnap }: Props) => {
  const handleChangeStartDate = useCallback(
    async (newValue: null | Timestamp) => {
      await updateDoc(cardSnap.ref, {
        'dates.start': newValue ? newValue?.toDate() : null,
      })
    },
    [cardSnap.ref],
  )

  const handleChangeEndDate = useCallback(
    async (newValue: null | Timestamp) => {
      await updateDoc(cardSnap.ref, {
        'dates.end': newValue ? newValue?.toDate() : null,
      })
    },
    [cardSnap.ref],
  )

  return (
    <Paper sx={{ width: '100%', p: 1, pl: 2, pr: 2, background: 'transparent' }} elevation={0}>
      <Typography
        sx={{ fontSize: 14, fontWeight: 'bold', mb: 2 }}
        color="text.secondary"
        gutterBottom
      >
        Dates
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start date"
          sx={{ mb: 3 }}
          // @ts-expect-error
          defaultValue={
            cardSnap.data()?.dates?.start
              ? dayjs(cardSnap.data()?.dates?.start?.toDate())
              : undefined
          }
          onChange={handleChangeStartDate}
        />
        <DatePicker
          label="End date"
          // @ts-expect-error
          defaultValue={
            cardSnap.data()?.dates?.end ? dayjs(cardSnap.data()?.dates?.end?.toDate()) : undefined
          }
          onChange={handleChangeEndDate}
        />
      </LocalizationProvider>
    </Paper>
  )
}

export default Dates
