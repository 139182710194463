import { collection, doc, DocumentReference } from 'firebase/firestore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { useFirestore, useSigninCheck } from 'reactfire'
import LoginPage from './Login'
import LogoutPage from './Logout'
import Projects from './Projects'

export type PageProps = {
  userRef: DocumentReference
}
const Router = () => {
  const { status: signInStatus, data: signInCheckResult } = useSigninCheck({
    forceRefresh: true,
  })

  const firestore = useFirestore()
  const [token, setToken] = useState<any>('')

  useEffect(() => {
    signInCheckResult?.user?.getIdTokenResult().then(tokenResult => {
      if (tokenResult) {
        setToken(tokenResult)
      }
    })
  }, [signInCheckResult?.user])

  const userId = useMemo(() => signInCheckResult?.user?.uid, [signInCheckResult?.user?.uid])

  const userRef = useMemo(
    () => (userId ? doc(collection(firestore, 'users'), userId) : undefined),
    [firestore, userId],
  )

  useEffect(() => {
    if (signInCheckResult?.signedIn) {
      ;(window as any).analytics?.identify(signInCheckResult?.user, {
        email: signInCheckResult?.user?.email,
      })
    }
  }, [signInCheckResult?.signedIn, signInCheckResult?.user])

  const LoadingSessionComponent = useCallback(
    ({ Component, FallbackComponent }: any) => {
      if (signInStatus === 'loading') {
        return <>Loading...</>
      }

      if (signInStatus === 'success' && signInCheckResult?.signedIn === true && userRef?.path) {
        return <Component userRef={userRef} />
      } else if (FallbackComponent) {
        return <FallbackComponent />
      } else {
        return <Navigate to={'/login'} />
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signInCheckResult?.signedIn, signInStatus, userRef?.path],
  )

  const router = createBrowserRouter([
    {
      path: '/',
      element: <LoadingSessionComponent Component={Projects} FallbackComponent={LoginPage} />,
    },
    {
      path: '/login',
      element: <LoadingSessionComponent Component={Projects} FallbackComponent={LoginPage} />,
    },
    {
      path: '/logout',
      element: <LoadingSessionComponent Component={LogoutPage} />,
    },
    {
      path: '/projects/:projectId/cards/:cardId?',
      element: <LoadingSessionComponent Component={Projects} FallbackComponent={LoginPage} />,
    },
    {
      path: '/projects/:projectId?',
      element: <LoadingSessionComponent Component={Projects} FallbackComponent={LoginPage} />,
    },
  ])

  return <RouterProvider router={router} />
}
export default Router
