import { CSSProperties, memo, useCallback, useEffect, useRef, useState } from 'react'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { DocumentReference, DocumentSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import InfoIcon from '@mui/icons-material/Info'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import CommentsIcon from './CommentsIcon'
import { WIDTH_COLUMN } from './BoardColumn'
import ActionableItems from './ActionableItems'
import ArchiveIcon from '@mui/icons-material/Archive'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import { TCard } from '../../hooks/useCardTree'
import ExpiringCard from './Card/ExpiringCard'

export const statusColor = (status: string, returnHex = false) => {
  switch (status) {
    case 'blocked':
      return returnHex ? '#f1564b' : 'error'
    case 'inProgress':
      return returnHex ? '#6b80d2' : 'primary'
    case 'pending':
      return returnHex ? '#c88bc4' : 'secondary'
    case 'warning':
      return returnHex ? '#ed6c02' : 'warning'
    case 'completed':
    default:
      // return returnHex ? '#b0f2c2' : 'success'
      return returnHex ? '#75d18e' : 'success'
  }
}

type Props = {
  cardSnap: DocumentSnapshot<TCard>
  userRef: DocumentReference
  style?: CSSProperties
  index: number
  onToggleCardDetailsModal: (cardSnap?: DocumentSnapshot<TCard>) => void
  handlerProps: any
  collapsed: boolean
}

const BoardCard = ({
  cardSnap,
  style = {},
  index,
  onToggleCardDetailsModal,
  handlerProps,
  userRef,
  collapsed,
}: Props) => {
  const [singleClickTimer, setSingleClickTimer] = useState<any>(null)
  const navigate = useNavigate()

  const rowRef = useRef(null)

  const handleClick = useCallback(() => {
    setSingleClickTimer(
      setTimeout(() => {
        onToggleCardDetailsModal(cardSnap)
      }, 125),
    )
  }, [cardSnap, onToggleCardDetailsModal])

  const handleClickPriority = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      const newPriority =
        typeof cardSnap.data()?.priority !== 'number' || cardSnap.data()?.priority! >= 6
          ? 0
          : cardSnap.data()?.priority! + 1
      await updateDoc(cardSnap.ref, {
        priority: newPriority,
      })
    },
    [cardSnap],
  )

  const handleDoubleClick = useCallback(() => {
    clearTimeout(singleClickTimer)
    navigate(`/projects/${cardSnap.ref.parent.parent!.id}/cards/${cardSnap.ref.id || ''}`)
  }, [cardSnap.ref.id, cardSnap.ref.parent.parent, navigate, singleClickTimer])

  useEffect(() => {
    return () => {
      clearTimeout(singleClickTimer)
    }
  }, [singleClickTimer])

  const handleChangeState = useCallback(
    async (state: TCard['state']) => {
      await updateDoc(cardSnap.ref, {
        state: state,
      })
    },
    [cardSnap.ref],
  )

  const handleArchive = useCallback(async () => {
    if (cardSnap.ref) {
      const newArchived = !cardSnap.data()?.archived

      await updateDoc(cardSnap.ref, {
        archived: newArchived,
        ...(cardSnap.data()?.archived
          ? {
              unarchivedAt: serverTimestamp(),
              unarchivedBy: userRef,
            }
          : {
              archivedAt: serverTimestamp(),
              archivedBy: userRef,
            }),
      })
    }
    return
  }, [cardSnap, userRef])

  return (
    <Box style={style}>
      <Card
        ref={rowRef}
        sx={{
          width: 275,
          ...(collapsed ? { height: '38px' } : {}),
          paddingLeft: 0,
          margin: '5px',
          '&:hover': {
            marginLeft: '6px',
          },
          opacity: cardSnap.data()!.archived ? 0.6 : 1,
        }}
        raised
        onDoubleClick={handleDoubleClick}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            sx={{
              background: statusColor(cardSnap.data()!.state, true),
              margin: 0,
              padding: 0,
              width: '8px',
              height: '180px',
              '& .changeStateIcon': {
                visibility: 'hidden',
              },
              ...(!collapsed
                ? {
                    '&:hover': {
                      width: 'auto',
                      background: 'inherit',
                      borderRight: 'solid 0.5px #F1F1F1',
                      '.changeStateIcon': {
                        visibility: 'visible',
                      },
                    },
                  }
                : {}),
            }}
          >
            <IconButton
              size="small"
              sx={{ color: statusColor('completed', true) }}
              className="changeStateIcon"
              onClick={() => handleChangeState('completed')}
            >
              <FiberManualRecordIcon />
            </IconButton>
            <IconButton
              size="small"
              sx={{ color: statusColor('warning', true) }}
              className="changeStateIcon"
              onClick={() => handleChangeState('warning')}
            >
              <FiberManualRecordIcon />
            </IconButton>
            <IconButton
              size="small"
              sx={{ color: statusColor('blocked', true) }}
              className="changeStateIcon"
              onClick={() => handleChangeState('blocked')}
            >
              <FiberManualRecordIcon />
            </IconButton>
            <IconButton
              size="small"
              sx={{ color: statusColor('inProgress', true) }}
              className="changeStateIcon"
              onClick={() => handleChangeState('inProgress')}
            >
              <FiberManualRecordIcon />
            </IconButton>
            <IconButton
              size="small"
              sx={{ color: statusColor('pending', true) }}
              className="changeStateIcon"
              onClick={() => handleChangeState('pending')}
            >
              <FiberManualRecordIcon />
            </IconButton>
          </Stack>

          <Stack
            sx={{ width: '100%', height: '180px' }}
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={0}
          >
            <CardContent sx={{ padding: '2px 8px 0 6px', width: '100%' }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                  <Box {...handlerProps} pt={'8px'}>
                    <DragIndicatorIcon
                      fontSize="small"
                      sx={{
                        cursor: 'grab',
                      }}
                    />
                  </Box>
                  <Tooltip title={cardSnap.data()!.title} arrow>
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      gutterBottom
                      maxWidth={`${WIDTH_COLUMN - (cardSnap.data()?.dates?.end ? 132 : 105)}px`}
                      overflow={'clip'}
                      noWrap
                    >
                      {cardSnap.data()?.archived ? (
                        <i>{cardSnap.data()!.title}</i>
                      ) : (
                        cardSnap.data()!.title
                      )}
                    </Typography>
                  </Tooltip>
                </Stack>
                <Box display={'flex'}>
                  <ExpiringCard cardSnap={cardSnap} />
                  <IconButton
                    size="small"
                    onClick={() =>
                      navigate(
                        `/projects/${cardSnap.ref.parent.parent!.id}/cards/${
                          cardSnap.ref?.id || ''
                        }`,
                      )
                    }
                  >
                    <KeyboardArrowRightIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Stack>

              {!collapsed && <Divider />}

              {!collapsed && (
                <Box
                  sx={{
                    overflow: 'scroll',
                    height: '106px',
                    pl: '2px',
                  }}
                >
                  <ActionableItems cardSnap={cardSnap} userRef={userRef} showAddButton />
                </Box>
              )}
              {/* <Markdown text={cardSnap.data()?.description} /> */}
            </CardContent>
            {!collapsed && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
                sx={{
                  padding: '0px 8px 0px 0px',
                  width: '100%',
                }}
              >
                <Tooltip arrow title="See details">
                  <IconButton
                    size="small"
                    onClick={handleClick}
                    onDoubleClick={e => {
                      e.stopPropagation()
                    }}
                  >
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Comments">
                  <CommentsIcon cardRef={cardSnap.ref} userRef={userRef} />
                </Tooltip>
                <Tooltip arrow title={cardSnap.data()?.archived ? 'Unarchive' : 'Archive'}>
                  <IconButton
                    onClick={handleArchive}
                    onDoubleClick={e => {
                      e.stopPropagation()
                    }}
                  >
                    {cardSnap.data()?.archived ? (
                      <UnarchiveIcon fontSize="small" />
                    ) : (
                      <ArchiveIcon fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>

                <Tooltip
                  arrow
                  title={`Priority: ${
                    typeof cardSnap.data()?.priority !== 'number' || cardSnap.data()?.priority === 6
                      ? 'No Priority'
                      : `P${cardSnap.data()?.priority}`
                  }`}
                >
                  <IconButton
                    size="small"
                    onClick={handleClickPriority}
                    onDoubleClick={e => {
                      e.stopPropagation()
                    }}
                  >
                    {typeof cardSnap.data()?.priority !== 'number' ||
                    cardSnap.data()?.priority === 6
                      ? 'NP'
                      : `P${cardSnap.data()?.priority}`}
                  </IconButton>
                </Tooltip>
                <Avatar
                  sx={{ width: 24, height: 24 }}
                  title={'Luciano Ganga'}
                  alt={'LG'}
                  // src={user.avatarUrl}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Card>
    </Box>
  )
}

export default memo(BoardCard)
