import { memo } from 'react'
import { styled } from '@mui/material/styles'
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

const HtmlTooltip = memo(
  styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      //   backgroundColor: theme.palette.background.paper,
      //   color: theme.palette.text.primary,
      maxWidth: 750,
      maxHeight: 'calc(100vh - 100px)',
      //   overflow: 'auto',
      //   border: theme.palette.mode === 'light' ? '1px solid #dadde9' : '1px solid #4c4e56',
    },
  })),
)
export default HtmlTooltip
